import React from 'react';
import "./CardValue.scss";

interface PropsValue {
    icn: string;
    title: string;
    text: string;
}

const CardValue = (Props: PropsValue) => {
    return (
        <div className="card-value center-vertically align-center">
            <div className="blc-icn">
                <img src={Props.icn} alt="icon"/>
            </div>
            <h1> {Props.title}</h1>
            <p>{Props.text}</p>
        </div>
    );
};

export default CardValue;
