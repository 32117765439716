import React, { useEffect, useRef } from "react";
import icn1 from "../../assets/icons/static-icn1.svg";
import icn2 from "../../assets/icons/static-icn2.svg";
import icn3 from "../../assets/icons/static-icn3.svg";
import icn4 from "../../assets/icons/static-icn4.svg";
import "./StaticSection.scss";
import { useTranslation } from "react-i18next";
import "../../assets/i18n";
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const StaticSection = () => {
  const { t, i18n } = useTranslation();
  const exp = React.useRef<HTMLHeadingElement>(null);
  const clients = React.useRef<HTMLHeadingElement>(null);
  const projects = React.useRef<HTMLHeadingElement>(null);
  const employees = React.useRef<HTMLHeadingElement>(null);
  const alreadyObserved = useRef(false);

  const restartCounter = (whichOne: React.RefObject<HTMLHeadingElement>) => {
    if (whichOne.current) {
      const dataCount = parseInt(whichOne.current.dataset.count as string);
      const printedValue = parseInt(whichOne.current?.innerText?.slice(0));
      const factor = 250 - (printedValue / dataCount) * 200;
      if (dataCount <= printedValue) {
        return;
      }
      whichOne.current.innerText = `${"+"}${printedValue + 1}${
        whichOne.current.dataset.count == "5"
          ? " " + t("home.static.years")
          : ""
      }`;

      setTimeout(() => {
        restartCounter(whichOne);
      }, factor);
    }
  };

  const staticRef = useRef(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    if (exp.current && !alreadyObserved.current) {
      alreadyObserved.current = true;
      const observer = new IntersectionObserver(
        (entries) => {
          console.log("false");

          if (entries[0]?.isIntersecting) {
            console.log("true");
            restartCounter(exp);
            restartCounter(clients);
            restartCounter(projects);
            restartCounter(employees);
          }
        },
        {
          root: staticRef.current,
          rootMargin: "0px",
          threshold: 1,
        }
      );
      observer.observe(exp.current);
    }
  }, []);
  useEffect(() => {
    gsap.fromTo(
      ".blc-static",
      {
        y: 80,
        opacity: 0,
        duration: 1,
        ease: "bounce",
        delay: 2,
        scrollTrigger: {
          trigger: ".blc-static",
          start: "top 50%",
        },
      },
      {
        y: 0,
        opacity: 1,
        duration: 2,
        scrollTrigger: {
          trigger: ".blc-static",
          start: "top 50%",
        },
      }
    );
  }, []);

  const dataStatic = [
    {
      img: icn1,
      title: "0 ",
      text: t("home.static.experience"),
      ref: exp,
      count: "5",
    },
    {
      img: icn2,
      title: "0",
      text: t("home.static.clients"),
      ref: clients,
      count: "28",
    },
    {
      img: icn3,
      title: "0",
      text: t("home.static.projects"),
      ref: projects,
      count: "30",
    },
    {
      img: icn4,
      title: "0",
      text: t("home.static.employees"),
      ref: employees,
      count: "30",
    },
  ];

  return (
    <div
      className="static-section center-horizontally space-between"
      ref={staticRef}
    >
      {dataStatic.map((item) => {
        return (
          <div className="blc-static center-horizontally align-center">
            <img src={item.img} alt="" />
            <div className="blc-text">
              <h1 data-count={item.count} ref={item.ref} className="data">
                {item.title}{" "}
              </h1>
              <p>{item.text} </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StaticSection;
