import React, {useEffect, useRef} from 'react';
import "./CardPortfolio.scss";
import SolutionTitle from "../solution-title/SolutionTitle";
import shape from "../../assets/icons/shape-portfol.svg";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";


interface Props {
    title: string,
    isRight?: boolean,
    link?:string,
    img: string,
    descript: string,
    className?: string,
}

const CardPortfolio = (Props: Props) => {

    const cardRef = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.fromTo(".right-card", {
            x: 500,
            opacity:0,
        }, {
            x: 0,
            opacity:1,
            duration: 2,
            delay:2,
            scrollTrigger: {
                trigger: ".right-card",
            }
        });

        gsap.fromTo(".left-card", {
            x: -500,
            opacity:0,
        }, {
            x: 0,
            opacity:1,
            duration: 2,
            delay: 2,
            scrollTrigger: {
                trigger: ".left-card",
            }
        });
    }, []);
    return (
        <div ref={cardRef}>
            {
                Props.isRight ?
                    <div className="card-portfolio right-portfolio center-horizontally align-center space-between" >
                        <div className="right-card center-vertically">
                            <SolutionTitle title={Props.title}/>
                            <p className="descript">{Props.descript}
                            </p>
                            <a href={Props.link} target="_blank">{Props.link}</a>
                        </div>
                        <div className="left-card">
                            <div className="shape">
                                <img src={shape} alt="img"/>
                            </div>
                            <img className={`img-portfolio ${Props.className}`}  src={Props.img} alt="img"/>
                        </div>
                    </div>

                    :
                    <div className="card-portfolio center-horizontally align-center">
                        <div className="left-card">
                            <div className="shape">
                                <img src={shape} alt="img"/>
                            </div>
                            <img className={`img-portfolio ${Props.className}`}  src={Props.img} alt="img"/>
                        </div>
                        <div className="right-card center-vertically">
                            <SolutionTitle title={Props.title}/>
                            <p className="descript">{Props.descript} </p>
                            <a href={Props.link} target="_blank">{Props.link}</a>
                        </div>
                    </div>

            }
        </div>

    );
};

export default CardPortfolio;
