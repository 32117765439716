import React, {useEffect, useRef} from 'react';
import "./History.scss";
import TitleSection from "../title-section/TitleSection";
import {useTranslation} from "react-i18next";
import "../../assets/i18n";
import img from "../../assets/images/img-history.png";
import imgMob from "../../assets/images/histoImg-mob.png";
import icn from "../../assets/icons/flech-histo.svg";
import shape from "../../assets/icons/shape-histo.svg";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const History = () => {
    const {t, i18n} = useTranslation();
    const historyRef = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.fromTo("#shapeHis", {
            right: -500,
            opacity: 0,
        }, {
            right: 0,
            opacity: 1,
            duration: 2,
            delay: 2,
            scrollTrigger: {
                trigger: "#shapeHis",
                start: "top 50%",

            }
        });

        gsap.fromTo("#titleHistory", {
            y: -50,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 2,
            ease: "bounce",
            delay: 2,
            scrollTrigger: {
                trigger: "#titleHistory",
                start: "top 50%",
            }
        });

        gsap.fromTo(".txtHisto", {
            y: 50,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 2,
            delay: 2,
            scrollTrigger: {
                trigger: ".txtHisto",
                start: "top 50%",
            }
        });
        gsap.fromTo("#imgSoon", {
            y: 50,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 2,
            delay: 2,
            scrollTrigger: {
                trigger: "#imgSoon",
                start: "top 50%",
            }
        });
        gsap.fromTo("#flech", {
            y: -30,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 2,
            delay: 2,
            scrollTrigger: {
                trigger: "#flech",
                start: "top 50%",
            }
        });
        gsap.fromTo(".titleInf", {
            y: 30,
            opacity: 0,
        }, {
            y: 0,
            opacity: 0.36,
            duration: 2,
            delay: 2,
            scrollTrigger: {
                trigger: ".titleInf",
                start: "top 50%",
            }
        });
        gsap.fromTo(".infos", {
            y: 50,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 2,
            delay: 2,
            scrollTrigger: {
                trigger: ".infos",
                start: "top 50%",
            }
        });
    }, []);
    return (
        <div className="history" ref={historyRef}>
            <img src={shape} alt="icon" className="shape" id="shapeHis"/>
            <div className="container center-vertically align-center">
                <div id="titleHistory">
                    <TitleSection title={t('about_us.history.title')} firstTitle={t('about_us.history.firstTitle')}/>
                </div>
                <div className="history-content">
                    {/* <p className="txtHisto">{t('about_us.history.txt')}</p> */}
                    <div className="blc-img" id="imgSoon">
                        <img src={img} alt="img" className="imgdesk"/>
                        <img src={imgMob} alt="img" className="imgmob"/>
                        <h1>{t('about_us.history.soon')}</h1>
                    </div>
                    <div className="infos-blc">
                        <div className="title-blc">
                            <img src={icn} alt="icon" id="flech"/>
                            <h1 className="titleInf">
                                {t('about_us.history.title2')}
                            </h1>
                        </div>
                        <div className="infos">
                            <p><span>Wimobi </span>{t('about_us.history.txt1')}</p>
                            <p>{t('about_us.history.txt2')}</p>
                            <p>{t('about_us.history.txt3')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default History;
