import React, {useEffect, useRef} from 'react';
import "./PortfolioFirstSection.scss";
import icn1 from "../../assets/images/itmatched.png";
import icn2 from "../../assets/images/digistole.png";
import icn3 from "../../assets/images/intigo.png";
import icn4 from "../../assets/images/edf.png";
import icn5 from "../../assets/images/fintch.png";
import icn6 from "../../assets/images/paied.png";
import icn7 from "../../assets/images/impact.png";
import icn8 from "../../assets/images/zen-park.png";
import "../../assets/i18n";
import {useTranslation} from "react-i18next";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const dataImg = [
    {
        img: icn1
    },
    {
        img: icn2
    },
    {
        img: icn3
    },
    {
        img: icn4
    },
    {
        img: icn5
    },
    {
        img: icn6
    },
    {
        img: icn7
    },
    {
        img: icn8
    },
]

const PortfolioFirstSection = () => {


    gsap.registerPlugin(ScrollTrigger);
    const refPort= useRef(null);


    useEffect(() => {
        gsap.fromTo("#first-t", {
            y: -50,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 1,
            delay: 0.5,
            scrollTrigger: {
                trigger: "#first-t",
            }
        });
        gsap.fromTo(".abs-title", {
            rotate: 360,
            y: 50,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 1,
            delay: 0.5,
            scrollTrigger: {
                trigger: ".abs-title",
            }
        });
        gsap.fromTo(".descript", {
            y: 50,
            opacity: 0,
            duration: 1,
        }, {
            rotate: 0,
            y: 0,
            opacity: 1,
            duration: 1,
            delay:0.5,
            scrollTrigger: {
                trigger: ".descript",
            }
        });
        gsap.fromTo(".cards", {
            y: -50,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 1,
            delay:0.5,
            scrollTrigger: {
                trigger: ".cards",
            }
        });




    }, []);
    const {t, i18n} = useTranslation();
    return (
        <div className="portfolio-f-s">
            <div className="container" ref={refPort}>
                <div className="title-p">
                    <h1 id="first-t">      {t('portfolio.section_1.title.part1')}<br/>
                        {t('portfolio.section_1.title.part2')}<span> {t('portfolio.section_1.title.part3')}</span></h1>
                    <div className={i18n.language == 'fr' ? "abs-title-fr" : "abs-title-en"}>
                        <h1>{t('portfolio.section_1.title.part1')}<br/>
                            {t('portfolio.section_1.title.part2')}<span> {t('portfolio.section_1.title.part3')}</span>
                        </h1>
                    </div>
                </div>
                <p className="descript"> {t('portfolio.section_1.descript')}</p>
                <div className="cards center-horizontally space-between">
                    {
                        dataImg.map(item => {
                            return (
                                <div className="card">
                                    <img src={item.img} alt="icn"/>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    );
};

export default PortfolioFirstSection;
