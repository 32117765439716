import React from 'react';
import "./Home.scss";
import Header from "../../components/header/Header";
import FirstSection from '../../components/first-section/FirstSection';
import AboutUs from "../../components/about-us/AboutUs";
import Service from "../../components/service/Service";
import CopyRight from "../../components/copy-right/CopyRight";
import ValueSection from "../../components/value-section/ValueSection";
import Contact from "../../components/contact/Contact";
import Reviews from "../../components/reviews/Reviews";
import Clients from "../../components/client/Clients";
import Portfolio from "../../components/portfolio/Portfolio";
import StaticSection from "../../components/static-section/StaticSection";
import Environment from "../../components/environment/Environment";
import Join from "../../components/join/Join";

const Home = () => {
    return (
        <div className="home">
            <Header/>
            <FirstSection/>
            <StaticSection />
            <AboutUs/>
            {/* <Environment /> */}
            <Service />
            <ValueSection />
            <Portfolio />
            <Clients />
            <Reviews />
            <Join />
            <Contact />
            <CopyRight />
        </div>
    );
};

export default Home;
