import React, { useEffect, useRef } from "react";
import "./Join.scss";
import TitleSection from "../title-section/TitleSection";
import { useTranslation } from "react-i18next";
import "../../assets/i18n";
import map from "../../assets/images/img-map.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";
import CardReview from "../card-review/CardReview";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Join = () => {
  const { t, i18n } = useTranslation();

  gsap.registerPlugin(ScrollTrigger);
  const refJoin = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      "#titleJoin",
      {
        y: -100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        delay: 1,
        scrollTrigger: {
          trigger: "#titleJoin",
          start: "top 50%",
        },
      }
    );
    gsap.fromTo(
      ".card-info",
      {
        x: -500,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 1,
        delay: 1,
        scrollTrigger: {
          trigger: ".blc-map",
          start: "top 50%",
        },
      }
    );
  }, []);

  const Addresses = [
    {
      title: "Wimobi Mahdia",
      address: t("address.label_1"),
      tel: "+216 73 655 822",
      email: "contact@wi-mobi.com",
    },
    {
      title: "Wimobi Monastir",
      address: t("address.label_2"),
      tel: "+216 70 287 827",
      email: "contact@wi-mobi.com",
    },
    {
      title: "Wimobi France",
      address: t("address.label_3"),
      tel: "+33 6 07 94 91 37",
      email: "contact@wi-mobi.com",
    },
  ];

  return (
    <div className="join" ref={refJoin}>
      <div className="center-horizontally justify-center" id="titleJoin">
        <TitleSection
          title={t("home.join.title")}
          firstTitle={t("home.join.firstTitle")}
        />
      </div>
      <div className="blc-map">
        <img src={map} alt="" />
        <div className="card-info">
          <Swiper
            slidesPerView={1}
            slidesPerGroup={1}
            loop={true}
            loopFillGroupWithBlank={true}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {Addresses.map((address) => {
              return (
                <SwiperSlide>
                  <div className="title-sec">
                    <h1>{address.title}</h1>
                    <div className="abs-title">
                      <h1>{address.title}</h1>
                    </div>
                  </div>

                  <div className="infos">
                    <p>{address.address}</p>
                    <p>{address.email}</p>
                    <p>{address.tel}</p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Join;
