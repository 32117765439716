import React, {useEffect, useRef} from 'react';
import "./EnvSecondSection.scss";
import TitleSection from "../title-section/TitleSection";
import {useTranslation} from "react-i18next";
import "../../assets/i18n";
import CardValue from "../card-value/CardValue";
import img from "../../assets/images/img-env.png";
import icn1 from "../../assets/icons/env-icn1.svg";
import icn2 from "../../assets/icons/env-icn2.svg";
import icn3 from "../../assets/icons/env-icn3.svg";
import icn4 from "../../assets/icons/env-icn4.svg";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const EnvSecondSection = () => {
    const {t} = useTranslation();
    const dataEnv = [
        {
            icn: icn1,
            title: t('environment.section_2.cards.card_1.title'),
            text: t('environment.section_2.cards.card_1.description')
        },
        {
            icn: icn2,
            title: t('environment.section_2.cards.card_2.title'),
            text: t('environment.section_2.cards.card_2.description')
        },
        {
            icn: icn3,
            title: t('environment.section_2.cards.card_3.title'),
            text: t('environment.section_2.cards.card_3.description')
        },
        {
            icn: icn4,
            title: t('environment.section_2.cards.card_4.title'),
            text: t('environment.section_2.cards.card_4.description')
        },

    ]

    const buildingRef = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.fromTo("#titleBuild", {
            y: -100,
            opacity:0,
        }, {
            y: 0,
            opacity:1,
            duration: 2,
            delay:2,
            scrollTrigger: {
                trigger: "#titleBuild",
            }
        });

        gsap.fromTo(".descript-env2", {
            y: 100,
            opacity:0,
        }, {
            y: 0,
            opacity:1,
            duration: 2,
            delay: 2,
            scrollTrigger: {
                trigger: ".descript-env2",

            }
        });

        gsap.fromTo(".blc-leftEnv", {
            x: -500,
            opacity:0,
        }, {
            x: 0,
            opacity:1,
            duration: 2,
            delay: 2,
            scrollTrigger: {
                trigger: ".blc-leftEnv",
            }
        });

        gsap.fromTo("#img-building", {
            x: 500,
            opacity:0,
        }, {
            x: 0,
            opacity:1,
            duration: 2,
            delay: 2,
            scrollTrigger: {
                trigger: "#img-building",
            }
        });
    }, []);

    return (
        <div className="env-s2" ref={buildingRef}>
            <div className="center-vertically align-center" id="titleBuild">
                <TitleSection title={t('environment.section_2.title')} firstTitle={t('environment.section_2.firstTitle')}/>
            </div>
            <div className="env2-content">
                <p className="descript-env2">{t('environment.section_2.descript')}</p>
                <div className="content-cards center-horizontally space-between">
                    <div className="blc-leftEnv">
                        <div className="cards-blc center-horizontally align-center space-between">
                            {
                                dataEnv.map(item => {
                                    return (
                                        <CardValue icn={item.icn} title={item.title} text={item.text}/>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="blc-img" id="img-building">
                        <img src={img} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EnvSecondSection;
