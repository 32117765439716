import React, { useEffect, useRef } from "react";
import "./Portfolio.scss";
import TitleSection from "../title-section/TitleSection";
import { useTranslation } from "react-i18next";
import "../../assets/i18n";
import img from "../../assets/images/img-portfolio.png";
import shape from "../../assets/icons/shape-portfol.svg";
import SolutionTitle from "../solution-title/SolutionTitle";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import img1 from "../../assets/images/img-portf.png";
import img2 from "../../assets/images/zid-img.png";
import img3 from "../../assets/images/edfImg.png";
import img4 from "../../assets/images/zen-park.png";
import img5 from "../../assets/images/fintchImg.png";
import img6 from "../../assets/images/intigoImg.png";
import img7 from "../../assets/images/paiedImg.png";
import img8 from "../../assets/images/digitsoleImg.png";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";
import type SwiperCore from 'swiper'
const Portfolio = () => {
  const { t, i18n } = useTranslation();
  gsap.registerPlugin(ScrollTrigger);
  const portfolioRef = useRef(null);
  const swiperRef = useRef<SwiperCore>()
  useEffect(() => {
    gsap.fromTo(
      ".title-portfolio",
      {
        y: -100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        delay: 2,
        scrollTrigger: {
          trigger: ".portfolio",
          start: "top 50%",
        },
      }
    );
    gsap.fromTo(
      ".img-portfolio",
      {
        x: -500,
        opacity: 0,
        duration: 2,
      },
      {
        x: 0,
        opacity: 1,
        duration: 1,
        delay: 2,
        scrollTrigger: {
          trigger: ".portfolio",
          start: "top 50%",
        },
      }
    );
    gsap.fromTo(
      ".right-portfolio",
      {
        x: 500,
        opacity: 0,
        duration: 2,
      },
      {
        x: 0,
        opacity: 1,
        duration: 1,
        delay: 2,
        scrollTrigger: {
          trigger: ".portfolio",
          start: "top 50%",
        },
      }
    );
    gsap.fromTo(
      "#shape-p",
      {
        rotate: 360,
        opacity: 0,
        duration: 2,
      },
      {
        rotate: 0,
        opacity: 1,
        duration: 1,
        delay: 2,
        scrollTrigger: {
          trigger: ".portfolio",
          start: "top 50%",
        },
      }
    );
  }, []);

  const Projects = [
    {
      title: "IT Matched",
      img: img1,
      descript: t("portfolio.cards.card1.description"),
      isRight: false,
      className: "",
      link : "https://itmatched.com"
    },
    {
      title: "ZiD.Tn",
      img: img2,
      descript: t("portfolio.cards.card2.description"),
      link: "https://zid.tn",
      isRight: true,
      className: "",
    },
    {
      title: "EDF",
      img: img3,
      descript: t("portfolio.cards.card3.description"),
      isRight: false,
      link: "www.edf.fr",
      className: "",
    },
    {
      title: "Zen Park",
      img: img4,
      descript: t("portfolio.cards.card4.description"),
      link: "https://zenpark.com",
      isRight: true,
      className: "",
    },
    {
      title: "Fintch",
      img: img5,
      descript: t("portfolio.cards.card5.description"),
      isRight: false,
      link: "https://fintch.fr",
      className: "",
    },
    {
      title: "Intigo",
      img: img6,
      descript: t("portfolio.cards.card6.description"),
      link: "https://intigo.tn",
      isRight: true,
      className: "mob-width",
    },
    {
      title: "Paied",
      img: img7,
      descript: t("portfolio.cards.card7.description"),
      isRight: false,
      link: "https://paied.co/home",
    },
    {
      title: "Digitsole",
      img: img8,
      descript: t("portfolio.cards.card8.description"),
      link: "https://digitsole.com",
      isRight: true,
      className: "mob-width",
    },
  ];

  return (
    <div className="portfolio" ref={portfolioRef}>
      <div className="center-vertically align-center title-portfolio">
        <TitleSection
          title={t("home.portfolio.title")}
          firstTitle={t("home.portfolio.firstTitle")}
        />
      </div>
      <Swiper
      
        navigation={false}
        modules={[Navigation]}
        className="swiper-contact"
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper
        }}
      >
        {Projects.map((project: any) => {
          return (
            <SwiperSlide>
              <div className="content-portfolio center-horizontally align-center">
                <div className="blc-left" id="left-portfolio">
                  <div className="shape" id="shape-p">
                    <img src={shape} alt="shape" />
                  </div>
                  <img className="img-portfolio" src={project.img} alt="img" />
                </div>

                <div className="right-portfolio">
                  <SolutionTitle title={project.title} />
                  <div>
                    <p className="descript">{project.descript}</p>
                  </div>
                  <div className="blc-bottom center-horizontally align-center space-between">
                    <a href={project.link} target="_blank" className="link-more">{project.link}</a>

                    <div className="blc-btn center-horizontally align-center space-between">
                      <button onClick={() => swiperRef.current?.slidePrev()}></button>
                      <button onClick={() => swiperRef.current?.slideNext()}></button>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Portfolio;
