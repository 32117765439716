import React, {useEffect, useRef} from 'react';
import "./Service.scss"
import TitleSection from "../title-section/TitleSection";
import {useTranslation} from "react-i18next";
import "../../assets/i18n";
import CardService from "../card-service/CardService";
import icn1 from "../../assets/icons/icn-serv1.png";
import icn2 from "../../assets/icons/icn-serv2.png";
import icn3 from "../../assets/icons/icn-serv3.png";
import icn4 from "../../assets/icons/icn-serv4.png";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const Service = () => {
    const {t, i18n} = useTranslation();
    const serviceRef = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.fromTo("#titleServ", {
            y: -100,
            opacity:0,
            duration: 1,
        }, {
            y: 0,
            opacity:1,
            duration: 1,
            delay: 2,
            ease: "bounce",
            scrollTrigger: {
                trigger: "#titleServ",
            }
        });
        gsap.fromTo(".blc-descript", {
            y: 200,
            opacity:0,
            duration: 1,
        }, {
            y: 0,
            opacity:1,
            duration: 1,
            delay: 2,
            scrollTrigger: {
                trigger: ".blc-descript",
            }
        });
        gsap.fromTo(".cards-left", {
            x: -500,
            opacity:0,
            duration: 1,
        }, {
            x: 0,
            opacity:1,
            duration: 1,
            delay: 2,
            scrollTrigger: {
                trigger: "#cards",
            }
        });
        gsap.fromTo(".cards-right", {
            x: 500,
            opacity:0,
            duration: 1,
        }, {
            x: 0,
            opacity:1,
            duration: 1,
            delay: 2,
            scrollTrigger: {
                trigger: "#cards",
            }
        });
    }, []);
    return (
        <div className="service-section">
             <div className="service-content center-vertically align-center" ref={serviceRef}>
                 <div id="titleServ">
                     <TitleSection title={t('home.service.title')} firstTitle={t('home.service.firstTitle')} />
                 </div>
                 <div className="blc-descript">
                     <h2>
                         {t('home.service.why_choose')}
                     </h2>
                     <p>
                         {t('home.service.text')}
                     </p>
                 </div>
                 <div className="cards" id="cards">
                     <div className="cards-left center-vertically">
                         <CardService icn={icn1} title={t('home.service.cards.card_1.title')} description={t('home.service.cards.card_1.descript')} />
                         <CardService icn={icn2} title={t('home.service.cards.card_2.title')} description={t('home.service.cards.card_2.descript')} />
                     </div>
                     <div className="cards-right center-vertically">
                         <CardService icn={icn3} title={t('home.service.cards.card_3.title')} description={t('home.service.cards.card_3.descript')} />
                         <CardService icn={icn4} title={t('home.service.cards.card_4.title')} description={t('home.service.cards.card_4.descript')} />
                     </div>
                 </div>
             </div>
        </div>
    );
};

export default Service;
