import React, { useEffect, useRef } from "react";
import "./Reviews.scss";
import TitleSection from "../title-section/TitleSection";
import { useTranslation } from "react-i18next";
import "../../assets/i18n";
import client1 from "../../assets/images/client1.png";
import client2 from "../../assets/images/client2.png";
import client3 from "../../assets/images/client3.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";
import CardReview from "../card-review/CardReview";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";



const Reviews = () => {
  const { t, i18n } = useTranslation();

  gsap.registerPlugin(ScrollTrigger);
  const reviewRef = useRef(null);
  const DataReviews = [
    {
      comment:
       t('home.review.review_1'),
      img: client1,
      name: "David jousselin",
      job: "client",
    },
    {
      comment:
      t('home.review.review_2'),
      img: client2,
      name: "Stéphane Rangoussis",
      job: "client",
    },
    {
      comment:
      t('home.review.review_3'),
      img: client3,
      name: "Nicolas Brignol",
      job: "client",
    },
  ];
  useEffect(() => {
    gsap.fromTo(
      ".title-review",
      {
        y: -50,
        opacity: 0,
      },
      {
        y: 0,
        delay: 2,
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: ".title-review",
          start: "top 50%",
        },
      }
    );
    gsap.fromTo(
      ".swiper-rev",
      {
        y: 250,
        opacity: 0,
      },
      {
        delay: 2,
        y: 0,
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: "#cards-rev",
          start: "top 50%",
        },
      }
    );
  }, []);

  return (
    <div className="reviews" ref={reviewRef}>
      <div className="title-review center-horizontally justify-center">
        <TitleSection
          title={t("home.reviews.title")}
          firstTitle={t("home.reviews.firstTitle")}
        />
      </div>
      <div className="blc-cards" id="cards-rev">
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          slidesPerGroup={3}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
            },
            "@0.75": {
              slidesPerView: 3,
            },
          }}
          loop={false}
          loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper swiper-rev"
        >
          {DataReviews.map((item) => {
            return (
              <SwiperSlide>
                <CardReview
                  img={item.img}
                  job={item.job}
                  name={item.name}
                  comment={item.comment}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default Reviews;
