import React from 'react';
import Header from "../../components/header/Header";
import Contact from "../../components/contact/Contact";
import CopyRight from "../../components/copy-right/CopyRight";
import RecFirstSection from "../../components/rec-first-section/RecFirstSection";
import InternshipSection from "../../components/internship-section/InternshipSection";
import ProcessSection from "../../components/process-section/ProcessSection";
import {useTranslation} from "react-i18next";
import "../../assets/i18n";

const Recruitment = () => {
    const {t, i18n} = useTranslation();

    return (
        <div className="recruitment">
            <Header/>
            <RecFirstSection/>
            <ProcessSection title={t('recruitment.process_job.title')}
                            firstTitle={t('recruitment.process_job.firstTitle')}/>
            <InternshipSection/>
            <ProcessSection title={t('recruitment.process_internship.title')}
                            firstTitle={t('recruitment.process_internship.firstTitle')} isInternship={true}/>
            <Contact/>
            <CopyRight/>
        </div>
    );
};

export default Recruitment;
