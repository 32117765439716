import React, {useEffect, useRef} from 'react';
import "./EnvfirstSection.scss";
import team from "../../assets/images/team-env.png";
import teamMob from "../../assets/images/img-mobEnv.png";
import shape from "../../assets/icons/shape-env.svg";
import {useTranslation} from "react-i18next";
import "../../assets/i18n";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const EnvFirstSection = () => {
    const {t, i18n} = useTranslation();
    const envRef = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.fromTo(".titBack", {
            y: -50,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 1,
            delay: 0.5,
            scrollTrigger: {
                trigger: ".titBack",
            }
        });

        gsap.fromTo(".abs-tit", {
            y: 50,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 1,
            delay: 0.5,
            scrollTrigger: {
                trigger: ".abs-tit",
            }
        });

        gsap.fromTo(".descript-env", {
            y: 30,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 1,
            delay: 0.5,
            scrollTrigger: {
                trigger: ".descript-env",
            }
        });

        gsap.fromTo("#img", {
            y: -50,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 1,
            delay: 0.5,
            scrollTrigger: {
                trigger: "#img",
            }
        });

        gsap.fromTo(".shape", {
            rotate: 360,
            x: -500,
        }, {
            rotate: 0,
            x: 0,
            opacity: 1,
            duration: 1,
            delay: 0.5,
            scrollTrigger: {
                trigger: ".shape",
            }
        });
    }, []);
    return (
        <div className="env-s1">
            <div className="container" ref={envRef}>
                <div className="titles">
                    <div className="title-bord">
                        <h1 className="firstTitle titBack">
                            {t('environment.section_1.title1')}
                        </h1>
                        <div className="abs-tit"><h1>{t('environment.section_1.title1')}</h1></div>
                    </div>
                    <div className="title-bord">
                        <h1 className="firstTitle titBack">
                            {t('environment.section_1.title2')}
                        </h1>
                        <div className="abs-tit"><h1> {t('environment.section_1.title2')}</h1></div>
                    </div>
                    <div className="title-bord">
                        <h1 className="firstTitle titBack">
                            {t('environment.section_1.title3')}
                        </h1>
                        <div className="abs-tit"><h1>{t('environment.section_1.title3')}</h1></div>
                    </div>

                </div>
                <p className="descript-env" id="descript">
                    {t('environment.section_1.descript')}
                </p>

                <div className="blc-img">
                    <img id="img" src={team} className="imgdesk" alt="img" draggable={false}/>
                    <img id="img" src={teamMob} className="imgmob" alt="img" draggable={false}/>
                    <div className="shape">
                        <img src={shape} alt="icon"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EnvFirstSection;
