import React from 'react';
import "./ItemContact.scss";
import {Link} from "react-router-dom";
import { PATHS } from '../../core/enums/Paths';

interface propsContact {
   img: string,
   title: string,
   btnTxt: string,
}

const ItemContact = (Props: propsContact) => {
    return (
        <div className="item-contact center-horizontally position-center">
            <img src={Props.img} alt="img"/>
            <div className="content-item center-horizontally position-center">
                <h1>{Props.title}</h1>
                <Link className="btn-crd center-horizontally position-center" to={PATHS.CONTACT}>
                    {Props.btnTxt}
                </Link>
            </div>
            <div className="mask center-horizontally position-center" />
        </div>
    );
};

export default ItemContact;
