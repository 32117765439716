import React from 'react';
import "./CardReview.scss";

interface Props {
    img: string;
    name: string;
    job: string;
    comment: string;
}

const CardReview = (Props: Props) => {
    return (
        <div className="card-review">
            <div className="blc-top center-horizontally align-center">
                <img src={Props.img} alt="img"/>
                <div className="txt-blc">
                    <h1>{Props.name}</h1>
                    <p>{Props.job}</p>
                </div>
            </div>
            <div className="comment">
                <p>
                    {Props.comment}
                </p>
            </div>
        </div>
    );
};

export default CardReview;
