import React, {useEffect, useRef} from 'react';
import "./EnvThirdSection.scss";
import flech from "../../assets/icons/flech.svg"
import {useTranslation} from "react-i18next";
import "../../assets/i18n";
import img1 from "../../assets/images/team/img1.png";
import img2 from "../../assets/images/team/img2.png";
import img3 from "../../assets/images/team/img3.png";
import img4 from "../../assets/images/team/img4.png";
import img5 from "../../assets/images/team/img5.png";
import img6 from "../../assets/images/team/img6.png";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const imgs = [
    {
        img: img1,
    },
    {
        img: img2,
    },
    {
        img: img3,
    },
    {
        img: img4,
    },
    {
        img: img5,
    },
    {
        img: img6,
    },
]

const EnvThirdSection = () => {
    const {t} = useTranslation();

    const teamRef = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.fromTo(".titleT", {
            y: -100,
            opacity:0,
        }, {
            y: 0,
            opacity:1,
            duration: 2,
            delay: 2,
            scrollTrigger: {
                trigger: ".titleT",
                start: "top 50%",
            }
        });

        gsap.fromTo(".flech", {
            rotate: -90,
            y: -100,
            opacity:0,
        }, {
            rotate: 0,
            y: 0,
            opacity:1,
            duration: 2,
            delay:2,
            scrollTrigger: {
                trigger: ".flech",
                start: "top 50%",
            }
        });

        gsap.fromTo(".abs-title", {
            y:100,
            opacity:0,
        }, {
            y: 0,
            opacity:1,
            duration: 2,
            delay: 2,
            scrollTrigger: {
                trigger: ".abs-title",
                start: "top 50%",
            }
        });

        gsap.fromTo(".blc-imgs", {
            y: 500,
            opacity:0,
        }, {
            y: 0,
            opacity:1,
            duration: 2,
            delay:2,
            scrollTrigger: {
                trigger: ".env-s3",
            }
        });
    }, []);

    return (
        <div className="env-s3" ref={teamRef}>
            <div className="container">
                <div className="title-sec">
                    <h1 className="titleT">{t('environment.section_3.title')}</h1>
                    <img className="flech" src={flech} alt="icon"/>
                    <div className="title-bord">
                        <h1>
                            {t('environment.section_3.title_1')}
                        </h1>
                        <div className="abs-title"><h1>{t('environment.section_3.title_1')}</h1></div>
                    </div>
                </div>
                <div className="blc-imgs center-horizontally justify-center">
                    {
                        imgs.map(img => {
                            return(
                                <img src={img.img} alt="img"/>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default EnvThirdSection;
