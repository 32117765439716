import React from 'react';
import Header from "../../components/header/Header";
import CopyRight from "../../components/copy-right/CopyRight";
import Join from "../../components/join/Join";
import Mission from "../../components/mission/Mission";
import Vision from "../../components/vision/Vision";
import VideoSection from "../../components/video-section/VideoSection";
import WhoAreWe from "../../components/who-are-we/WhoAreWe";
import History from "../../components/history/History";

const AboutPage = () => {
    return (
        <div className="about-page">
            <Header/>
            <VideoSection />
            <WhoAreWe />
            <Mission/>
            <Vision />
            <History />
            <Join/>
            <CopyRight/>
        </div>
    );
};

export default AboutPage;
