import React from 'react';
import "./SolutionTitle.scss";

interface Props {
    title: string;
}

const SolutionTitle = (Props: Props) => {
    return (
        <div className="solution-title">
            <h1>Solution</h1>
            <h2>{Props.title}</h2>
        </div>
    );
};

export default SolutionTitle;
