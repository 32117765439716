import React, {useEffect, useRef} from 'react';
import "./InternshipSection.scss";
import img from "../../assets/images/intern.png";
import flech from "../../assets/icons/flech-internship.svg";
import shapeTop from "../../assets/icons/shapeTopRec.svg";
import shapeBottom from "../../assets/icons/shapeBottomRec.svg";
import {useTranslation} from "react-i18next";
import "../../assets/i18n";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import icnComment from '../../assets/icons/fill-comment.png'



const InternshipSection = () => {
    const {t} = useTranslation();
    const recRef3 = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.fromTo(".left-side", {
            x: -500,
            opacity: 0,
        }, {
            x: 0,
            opacity: 1,
            duration: 2,
            delay:2,
            scrollTrigger: {
                trigger: ".left-side",
            }
        });
        gsap.fromTo("#imgI", {
            x: 500,
            opacity: 0,
        }, {
            x: 0,
            opacity: 1,
            duration: 2,
            delay:2,
            scrollTrigger: {
                trigger: "#imgI",
            }
        });
        gsap.fromTo("#shape-img-top", {
            y: -50,
            rotate: 360,
            opacity: 0,
        }, {
            y: 0,
            rotate: 0,
            opacity: 1,
            duration: 2,
            delay:2,
            scrollTrigger: {
                trigger: "#shape-img-top",
            }
        });
        gsap.fromTo("#shape-img-bottom", {
            y: -50,
            rotate: 360,
            opacity: 0,
        }, {
            y: 0,
            rotate: 0,
            opacity: 1,
            duration: 2,
            delay:2,
            scrollTrigger: {
                trigger: "#shape-img-bottom",
            }
        });

    }, []);

    return (
        <div className="internship-section" ref={recRef3}>
            <div className="container center-horizontally align-center">
                <div className="left-side center-vertically align-center">
                    <div className="title-bord">
                        <h1>
                            {t('recruitment.section_2.title1')}
                            <span>{t('recruitment.section_2.part2')} </span>
                        </h1>
                        <div className="abs-title"><h1>  {t('recruitment.section_2.title1')}
                            <span>  {t('recruitment.section_2.part2')} </span></h1></div>
                    </div>
                    <p className="descript">{t('recruitment.section_2.txt')} 
                    <p className='name'>Albert Einstein</p>
            <img src={icnComment} alt="" />
                    </p>
                    <h1 className="second-title">{t('recruitment.internship_section.title2')} <img className="flech"
                                                                                                   src={flech} alt="icon"/>
                    </h1>
                </div>
                <div className="blc-img">
                    <img src={shapeTop} alt="" className="shape-img top" id="shape-img-top"/>
                    <img src={img} alt="img" id="imgI"/>
                    <img src={shapeBottom} alt="" className="shape-img bottom" id="shape-img-bottom"/>
                </div>
            </div>
        </div>
    );
};

export default InternshipSection;
