import React, {useEffect, useRef, useState} from 'react';
import "./Header.scss";
import {Link, useLocation} from "react-router-dom";
import logo from "../../assets/icons/logo.svg";
import icon from "../../assets/icons/hamburger-icn.png";
import closeIcon from "../../assets/icons/close.svg";
import "../../assets/i18n";
import {useTranslation} from "react-i18next";
import {PATHS} from "../../core/enums/Paths";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const Header = () => {
    const location = useLocation();
    const {pathname} = location;
    const splitLocation = pathname.split("/");
    const isActive = (item: string) =>
        splitLocation[1] === item ? "active" : "";
    const {t, i18n} = useTranslation();
    const change = (event: any) => {
        if (event.target.value === "fr") {
            i18n.changeLanguage("fr");
        } else {
            i18n.changeLanguage("en");
        }
    }
    gsap.registerPlugin(ScrollTrigger);
    const refHeader = useRef(null);


    useEffect(() => {
        gsap.fromTo(".header", {
            opacity: 0,
            duration: 1,
            ease: "bounce",
            delay: 1,
            scrollTrigger: {
                trigger: ".header",
            }
        }, {
            opacity: 1,
            duration: 1,
            scrollTrigger: {
                trigger: ".header",
            }
        });


    }, []);
    const [open, setOpen] = useState(false);

    const onOpenMenu = () => {
        setOpen(true);
        // document.body.style.overflow = 'hidden'
    };
    const onCloseMenu = () => {
        setOpen(false);
        // document.body.style.overflow = 'auto'
    }


    return (
        <div className="header" ref={refHeader}>
            <div className="container center-horizontally space-between align-center">
                <Link to='/' className="logo">
                    <img src={logo} alt="logo"/>
                </Link>
                <div className="blc-right center-horizontally desk">
                    <nav className="nav-desk center-horizontally space-between align-center">
                        <Link to={PATHS.HOME} className={`nav-link ${isActive("home")}`}>
                            {t('header.home')}
                        </Link>
                        <Link to={PATHS.ABOUT} className={`nav-link ${isActive("about")}`}>
                            {t('header.aboutUs')}
                        </Link>
                        <Link to={PATHS.PORTFOLIO} className={`nav-link ${isActive("portfolio")}`}>
                            {t('header.Portfolio')}
                        </Link>
                        <Link to={PATHS.ENVIRONMENT} className={`nav-link ${isActive("environment")}`}>
                            {t('header.environment')}
                        </Link>
                        <Link to={PATHS.RECRUITMENT} className={`nav-link ${isActive("recruitment")}`}>
                            {t('header.recruitment')}
                        </Link>
                        <Link to={PATHS.CONTACT} className={`nav-link ${isActive("contact")}`}>
                            {t('header.Contact')}
                        </Link>

                    </nav>
                    <select value={i18n.language} id="lang" onChange={change}>
                        <option value="fr"> {t('header.french')}</option>
                        <option value="en"> {t('header.english')}</option>
                    </select>
                </div>
                <button className={` hmbr-menu ${open && "close-menu"}`} onClick={() => {
                    open ? onCloseMenu()
                        : onOpenMenu()
                }}>
                    <img src={open ? closeIcon : icon} alt="icon"/>
                </button>
            </div>
            {
                open &&
                <div className="menu-mob">
                    <div className="blc-right center-vertically position-align-left-vertical">
                        <nav className="nav-desk center-vertically position-align-left-vertical">
                            <Link to={PATHS.HOME} className={`nav-link ${isActive("home")}`}>
                                {t('header.home')}
                            </Link>
                            <Link to={PATHS.ABOUT} className={`nav-link ${isActive("about")}`}>
                                {t('header.aboutUs')}
                            </Link>
                            <Link to={PATHS.PORTFOLIO} className={`nav-link ${isActive("portfolio")}`}>
                                {t('header.Portfolio')}
                            </Link>
                            <Link to={PATHS.ENVIRONMENT} className={`nav-link ${isActive("environment")}`}>
                                {t('header.environment')}
                            </Link>
                            <Link to={PATHS.RECRUITMENT} className={`nav-link ${isActive("recruitment")}`}>
                                {t('header.recruitment')}
                            </Link>
                            <Link to={PATHS.CONTACT} className={`nav-link ${isActive("contact")}`}>
                                {t('header.Contact')}
                            </Link>

                        </nav>
                        <select value={i18n.language} id="lang" onChange={change}>
                            <option value="fr"> {t('header.french')}</option>
                            <option value="en"> {t('header.english')}</option>
                        </select>
                    </div>

                </div>
            }
        </div>
    );
};

export default Header;
