import React from 'react';
import "./CopyRight.scss"
import icn1 from "../../assets/icons/icn-link.svg";
import icn2 from "../../assets/icons/icn-inst.svg";
import icn3 from "../../assets/icons/icn-fb.svg";
import {useTranslation} from "react-i18next";
import "../../assets/i18n";

const CopyRight = () => {
    const {t, i18n} = useTranslation();
    const date = new Date().getFullYear();

    return (
        <div className="copy-right">
            <div className="container center-horizontally space-between align-center">
                <p>©Wimobi{date}</p>
                <div className="center-horizontally align-center">
                 <p>{t('home.copy_right.join_us')} </p>
                  <div className="s-media center-horizontally">
                      <a href='https://www.linkedin.com/company/wimobi/mycompany/' target='_blank'><img src={icn1} alt=""/></a>
                      <a href='https://www.instagram.com/_wimobi_/' target='_blank'><img src={icn2} alt=""/></a>
                      <a href='https://www.facebook.com/profile.php?id=100054603542557' target='_blank'><img src={icn3} alt=""/></a>
                  </div>
                </div>
            </div>
        </div>
    );
};

export default CopyRight;
