import React from 'react';
import "./TitleContact.scss";

interface Props {
    title: string;
    nbr: string;
}

const TitleContact = (Props: Props) => {
    return (
        <div className="title-contact center-horizontally space-between align-center">
            <h2 className="title-c center-horizontally align-center">{Props.title}</h2>
            <h1>{Props.nbr}</h1>
        </div>
    );
};

export default TitleContact;
