import React, { Fragment, useState } from "react";
import TitleContact from "../../../components/title-contact/TitleContact";
import { useTranslation } from "react-i18next";
import "../../../assets/i18n";
import Input from "../../../components/input/Input";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import upload from "../../../assets/images/upload.svg";
import axios from "axios";
import { PATHS } from "../../../core/enums/Paths";
import { toast } from "react-toastify";

const Form2 = () => {
  const { t, i18n } = useTranslation();
  const [dataInputs, setDataInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  });
  const [currentFile, setCurrentFile] = useState<any>();
  const onChangeInputs = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDataInputs({
      ...dataInputs,
      [e.target.name]: e.target.value,
    });
  };
  const [contrat, setContrat] = useState("");
  const [profil, setProfil] = useState("");
  const handleChangeContrat = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContrat((event.target as HTMLInputElement).value);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProfil((event.target as HTMLInputElement).value);
  };
  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const selectedFiles = files as FileList;
    setCurrentFile(selectedFiles?.[0]);
  };

  const dataForms = [
    {
      id: 1,
      title: t("contact.card2.from.section1.title"),
      nbr: "01",
      form: [
        {
          text: "*" + t("contact.name"),
          type: "text",
          name: "firstName",
          value: dataInputs.firstName,
        },
        {
          text: "*" + t("contact.FirstName"),
          type: "text",
          name: "lastName",
          value: dataInputs.lastName,
        },
        {
          text: "*" + t("contact.mail"),
          type: "email",
          name: "email",
          value: dataInputs.email,
        },
        {
          text: "*" + t("contact.phone"),
          type: "text",
          name: "phone",
          value: dataInputs.phone,
        },
        {
          text: t("contact.company"),
          type: "text",
          name: "company",
          value: dataInputs.company,
        },
      ],
    },
    {
      id: 2,
      title: t("contact.card2.from.section2.title"),
      nbr: "02",
      isRadio: true,
      form: [
        {
          contrat: t("contact.forms.contrat.type_1"),
        },
        {
          contrat: t("contact.forms.contrat.type_2"),
        },
      ],
    },
    {
      id: 3,
      title: t("contact.card2.from.section3.title"),
      nbr: "03",
      isRadio: true,
      form: [
        {
          label: t("contact.forms.profil.profil_1"),
        },
        {
          label: t("contact.forms.profil.profil_2"),
        },
        {
          label: t("contact.forms.profil.profil_3"),
        },
        {
          label: t("contact.forms.profil.profil_4"),
        },
        {
          label: t("contact.forms.profil.profil_5"),
        },
        {
          label: t("contact.forms.profil.profil_6"),
        },
        {
          label: t("contact.forms.profil.profil_7"),
        },
        {
          label: t("contact.forms.profil.profil_8"),
        },
        {
          label: t("contact.forms.profil.profil_9"),
        },
      ],
    },
    {
      id: 4,
      title: t("contact.card2.from.section4.title"),
      nbr: "04",
      form: [
        {
          labelArea: t("contact.forms.message.label"),
          name: "message",
          value: dataInputs.message,
          type: "text",
        },
      ],
    },
  ];

  const onSubmit = async () => {
    const body = new FormData();
    body.append("firstName", dataInputs.firstName);
    body.append("lastName", dataInputs.lastName);
    body.append("email", dataInputs.email);
    body.append("phone", dataInputs.phone);
    body.append("company", dataInputs.company);
    body.append("subject", "job");
    body.append("contrat", contrat);
    body.append("profile", profil);
    body.append("cv", currentFile);

    body.append("message", dataInputs.message);
    await axios({
      method: "post",
      url: PATHS.API_CONTACT,
      data: body,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (response.status === 200) {
          toast.success("Votre message a été envoyé avec succèss");
          setDataInputs({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            company: "",
            message: "",
          });
          setContrat("");
          setCurrentFile(undefined);
          setProfil("");
        } else {
          toast.error("Veuillez vérifier vos données");
        }
      })
      .catch(function (response) {
        toast.error("Veuillez vérifier vos données");
      });
  };

  return (
    <div className="forms">
      {dataForms.map((item) => {
        return (
          <div key={item.id}>
            <TitleContact title={item.title} nbr={item.nbr} />
            <div className={`content-form ${item.isRadio && "grid"}`}>
              {item.form?.map((form: any) => {
                return (
                  <Fragment>
                    {form.text && (
                      <Input
                        onChange={onChangeInputs}
                        value={form.value}
                        name={form.name}
                        text={form.text}
                        type={form.type}
                      />
                    )}
                    {form.contrat && (
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={contrat}
                          onChange={handleChangeContrat}
                        >
                          <FormControlLabel
                            value={form.contrat}
                            control={<Radio />}
                            label={form.contrat}
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                    {form.label && (
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={profil}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value={form.label}
                            control={<Radio />}
                            label={form.label}
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                    {form.labelArea && (
                      <Fragment>
                        <Input
                          isTextArea
                          onChange={onChangeInputs}
                          value={form.value}
                          name={form.name}
                          text={form.labelArea}
                          type={form.type}
                        />
                        <div className="blc-upload center-horizontally align-center">
                          <input type="file" onChange={selectFile} />
                          <img src={upload} alt="" />
                          <p>{t("contact.upload_cv")}</p>
                        </div>
                      </Fragment>
                    )}
                    {/* {item.isBudget && (
  
                      )} */}
                  </Fragment>
                );
              })}
            </div>
          </div>
        );
      })}
      <div className="blc-action center-horizontally align-center space-between">
        <p className="appreciation">{t("contact.appreciation")}</p>
        <button onClick={onSubmit} className="primary-btn">
          {t("contact.send")}
        </button>
      </div>
    </div>
  );
};

export default Form2;
