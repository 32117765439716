import React, { useState } from "react";
import "./Contact.scss";
import Header from "../../components/header/Header";
import CopyRight from "../../components/copy-right/CopyRight";
import img from "../../assets/images/img1-contact.png";
import img2 from "../../assets/images/img2-contact.png";
import img3 from "../../assets/images/img3-contact.png";
import Form1 from "./forms/Form1";
import Form2 from "./forms/Form2";
import Form3 from "./forms/Form3";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";
import { useTranslation } from "react-i18next";
import "../../assets/i18n";

const Contact = () => {
  const { t, i18n } = useTranslation();
  const [swiperIndex, setSwiperIndex] = useState<number>(0);

  const dataContact = [
    {
      id: 1,
      img: img,
      title: t("contact.card1.title"),
      nextTitle: t("contact.card1.nextTitle"),
      text: t("contact.card3.text"),
    },
    {
      id: 2,
      img: img2,
      title: t("contact.card2.title"),
      prevTitle: t("contact.card2.prevTitle"),
      nextTitle: t("contact.card2.nextTitle"),
      text: t("contact.card3.text"),
    },
    {
      id: 3,
      img: img3,
      title: t("contact.card3.title"),
      prevTitle: t("contact.card3.prevTitle"),
      text: t("contact.card3.text"),
    },
  ];

  return (
    <div className="contact">
      <Header />
      <Swiper
        onSlideChange={(e) => {
          setSwiperIndex(e.activeIndex);
        }}
        navigation={true}
        modules={[Navigation]}
        className="swiper-contact"
      >
        {dataContact &&
          dataContact.map((item) => {
            return (
              <SwiperSlide key={item.id}>
                <div className="contact-content">
                  <div className="blc-img">
                    <img src={item.img} alt="img" />
                    <div className="blc-abs center-vertically position-center">
                      <div className="prev-title">
                        <h1>{item.prevTitle}</h1>
                      </div>
                      <h1>{item.title} </h1>
                      <p>{item.text} </p>
                      <div className="next-title">
                        <h1>{item.nextTitle}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
      <div className="card-contact">
        {swiperIndex === 0 ? (
          <Form1 />
        ) : swiperIndex === 1 ? (
          <Form2 />
        ) : (
          <Form3 />
        )}
      </div>
      <CopyRight />
    </div>
  );
};

export default Contact;
