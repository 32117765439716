import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./assets/fonts/poppins/poppins.css";
import "./App.scss";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import { PATHS } from "./core/enums/Paths";
import Home from "./pages/home/Home";
import PortfolioPage from "./pages/portfolio-page/PortfolioPage";
import EnvironmentPage from "./pages/environment-page/EnvironmentPage";
import Recruitment from "./pages/recruitment/Recruitment";
import AboutPage from "./pages/about-page/AboutPage";
import Contact from "./pages/contact/Contact";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [isUp, setIsUp] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.pageYOffset > 200 ? setIsUp(true) : setIsUp(false);
    });
  }, [window.pageYOffset]);

  const onUp = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div className="App">
      {isUp && <div onClick={onUp} className="btn-up"></div>}

      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path={PATHS.HOME} element={<Home />} />
            <Route path={PATHS.ABOUT} element={<AboutPage />} />
            <Route path={PATHS.PORTFOLIO} element={<PortfolioPage />} />
            <Route path={PATHS.ENVIRONMENT} element={<EnvironmentPage />} />
            <Route path={PATHS.RECRUITMENT} element={<Recruitment />} />
            <Route path={PATHS.CONTACT} element={<Contact />} />
            <Route path="/" element={<Navigate replace to={PATHS.HOME} />} />
          </Routes>
        </ScrollToTop>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          theme="light"
        />
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
