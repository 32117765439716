import React, {useEffect, useRef} from 'react';
import "./Contact.scss";
import TitleSection from "../title-section/TitleSection";
import {useTranslation} from "react-i18next";
import "../../assets/i18n";
import ItemContact from "../item-contact/ItemContact";
import img1 from "../../assets/images/contact-img1.png";
import img2 from "../../assets/images/contact-img2.png";
import img3 from "../../assets/images/contact-img3.png";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const Contact = () => {
    const {t, i18n} = useTranslation();
    const dataContact = [
        {
            img: img1,
            title: t('home.contact.items.item_1.title'),
            btnText: t('home.contact.items.item_1.btn_text')
        },
        {
            img: img2,
            title: t('home.contact.items.item_2.title'),
            btnText: t('home.contact.items.item_2.btn_text')
        },
        {
            img: img3,
            title: t('home.contact.items.item_3.title'),
            btnText: t('home.contact.items.item_2.btn_text')
        }
    ]

    gsap.registerPlugin(ScrollTrigger);
    const refContact = useRef(null);


    useEffect(() => {
        gsap.fromTo("#titleContact", {
            y: -100,
            opacity:0,
        }, {
            y: 0,
            opacity:1,
            duration: 1,
            delay: 0.5,
            scrollTrigger: {
                trigger: "#titleContact",
            }
        });
        // gsap.fromTo(".text-contact", {
        //     y: 100,
        //     opacity:0,
        // }, {
        //     y: 0,
        //     opacity:1,
        //     duration: 1,
        //     delay: 2,
        //     scrollTrigger: {
        //         trigger: ".text-contact",
        //         start: "top 50%",
        //     }
        // });
        gsap.fromTo(".items-contact", {
            y: 50,
            opacity:0,
        }, {
            y: 0,
            opacity:1,
            duration: 1,
            delay: 0.5,
            scrollTrigger: {
                trigger: ".items-contact",
                start: "top 50%",

            }
        });
    }, []);

    return (
        <div className="contact-section">
            <div className="container" ref={refContact}>
                <div className="center-vertically align-center title-container">
                    <div  id="titleContact">
                        <TitleSection title={t('home.contact.title')} firstTitle={t('home.contact.firstTitle')}/>

                    </div>
                </div>
                <div className="items-contact center-horizontally align-center space-between">
                    {
                        dataContact.map(item => {
                            return (
                                <ItemContact img={item.img} title={item.title} btnTxt={item.btnText}/>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default Contact;
