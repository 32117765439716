import React, {useEffect, useRef} from 'react';
import "./AboutUs.scss";
import img from "../../assets/images/img-about.png";
import TitleSection from "../title-section/TitleSection";
import "../../assets/i18n";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {gsap, Power3} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { PATHS } from '../../core/enums/Paths';

const AboutUs = () => {
    const {t, i18n} = useTranslation();

    const aboutRef = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.fromTo("#imgAbout", {
            x: -300,
            opacity: 0,
            duration: 1,
            ease: "bounce",
        }, {
            x: 0,
            opacity: 1,
            duration: 1,
            delay: 1,
            scrollTrigger: {
                trigger: "#about",
                start: "top 50%",
            }
        });
        gsap.fromTo("#rightSide", {
            x: 300,
            opacity: 0,
            duration: 1,
            ease: "bounce",
        }, {
            x: 0,
            opacity: 1,
            duration: 1,
            delay: 1,
            scrollTrigger: {
                trigger: "#about",
                start: "top 50%",
            }
        });
    }, []);

    return (
        <div className="about-section" ref={aboutRef}>
            <div className="container center-horizontally space-between align-center" id="about">
                <div className="blc-img" id="imgAbout">
                    <img src={img} alt=""/>
                </div>
                <div className="right-side" id="rightSide">
                    <div className="tit-mob">
                        <TitleSection title={t('home.aboutSection.title')} firstTitle={t('home.aboutSection.firstTitle')}/>
                    </div>
                    <div className="descript-about">
                        <p><span>{t('home.aboutSection.created')}</span>{t('home.aboutSection.text')}</p>
                    </div>
                    <Link className="link-discover" to={PATHS.ABOUT}>{t('home.aboutSection.btn')}</Link>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
