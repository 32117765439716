import React from "react";
import "./Input.scss";
import attach from "../../assets/images/attach.svg";

interface Props {
  isTextArea?: boolean;
  text: string;
  type?: string;
  value?: string;
  name?: string;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const Input = (Props: Props) => {
  return (
    <>
      {Props.isTextArea ? (
        <div className="blc-input">
          {Props.value && <label htmlFor={Props.name}>{Props.text}</label>}
          <textarea
            value={Props.value}
            typeof={Props.type}
            name={Props.name}
            onChange={Props.onChange}
            rows={5}
            placeholder={Props.text}
          />
          {/* <img src={attach} alt=""/> */}
        </div>
      ) : (
        <div className="blc-input">
          {Props.value && <label htmlFor={Props.name}>{Props.text}</label>}
          <input
            value={Props.value}
            name={Props.name}
            onChange={Props.onChange}
            type={Props.type}
            placeholder={Props.text}
          />
        </div>
      )}
    </>
  );
};

export default Input;
