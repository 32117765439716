import React, {useEffect, useRef} from 'react';
import "./ValueSection.scss";
import TitleSection from "../title-section/TitleSection";
import {useTranslation} from "react-i18next";
import "../../assets/i18n";
import {Link} from "react-router-dom";
import CardValue from "../card-value/CardValue";
import icn1 from "../../assets/icons/icn-value1.svg";
import icn2 from "../../assets/icons/icn-value2.svg";
import icn3 from "../../assets/icons/icn-value3.svg";
import arrowIcn from "../../assets/icons/arrow-value.svg"
import img from "../../assets/images/img-value.png"
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";



const ValueSection = () => {
    const {t} = useTranslation();

    const valueRef = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.fromTo(".titleValue", {
            y: -150,
            opacity:0,
            duration: 1,
        }, {
            y: 0,
            delay: 1,
            opacity:1,
            duration: 1,
            scrollTrigger: {
                trigger: ".titleValue",
                start: "top 50%",
            }
        });
        gsap.fromTo(".blc-left", {
            x: -450,
            opacity:0,
            duration: 1,
        }, {
            x: 0,
            opacity:1,
            duration: 1,
            delay: 1,
            scrollTrigger: {
                trigger: ".blc-left",
                start: "top 50%",
            }
        });
        gsap.fromTo("#blc-img", {
            x: 450,
            opacity:0,
            duration: 1,

        }, {
            x: 0,
            opacity:1,
            duration: 1,
            delay:1,
            scrollTrigger: {
                trigger: "#blc-img",
                start: "top 50%",
            }
        });
    }, []);
    const dataValue = [
        {
            icn: icn1,
            title: t('home.value.cards.card_1.title'),
            text: t('home.value.cards.card_1.description')
        },
        {
            icn: icn2,
            title: t('home.value.cards.card_2.title'),
            text: t('home.value.cards.card_2.description')
        },
        {
            icn: icn3,
            title: t('home.value.cards.card_3.title'),
            text: t('home.value.cards.card_3.description')
        },


    ]

    return (
        <div className="value-section center-vertically">
            <div className="center-vertically align-center" ref={valueRef}>
                <div className="titleValue">
                    <TitleSection title={t('home.value.title')} firstTitle={t('home.value.firstTitle')}/>
                </div>
            </div>
            <div className="value-content center-horizontally space-between">
                <div className="blc-left">
                    <div className="blc-text">
                        <h1>{t('home.value.subTitle')}</h1>
                        <p>{t('home.value.text')}</p>
                    </div>
                    <div className="cards-blc center-horizontally align-center space-between">
                        {
                            dataValue.map(item => {
                                return (
                                    <CardValue icn={item.icn} title={item.title} text={item.text}/>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="img-value" id="blc-img">
                    <img src={img} alt=""/>
                </div>
            </div>
        </div>
    );
};

export default ValueSection;
