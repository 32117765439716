import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  fr: {
    translation: {
      header: {
        home: "Accueil",
        aboutUs: "À propos",
        recruitment: "Recrutement",
        environment: "Environnement",
        Portfolio: "Portfolio",
        Contact: "Contact",
        english: "Anglais",
        french: "Français",
      },
      home: {
        section_1: {
          txt1: "Ensemble , nous pouvons rêver et innover votre business",
          title_1: "Votre PARTENAIRE dans",
          title_2: "la TRANSFORMATION digitale",
          descript:
            "Quelle que soit votre activité , vos besoins, vos objectifs , nous vous offrons des possibilités infinies  pour vos solutions web, mobile et  design.",
          btn: "Démarrer un projet",
        },
        static: {
          years: "ans",
          experience: "Expérience",
          clients: "Clients",
          projects: "Projets",
          employees: "Employées",
        },
        aboutSection: {
          firstTitle: "Wi MOBI",
          title: "À propos de nous",
          created: "Crée en 2017",
          text: " , Wimobi est une agence international de services numériques qui offre à ses clients des services digitales à la pointe des technologies les plus récentes et des solutions les plus innovantes.",
          btn: "Découvrir plus",
        },
        environment: {
          firstTitle: "Wi ENVIRONNEMENT",
          title: "Au sein de wimobi",
        },
        service: {
          firstTitle: "Wi SERVICES",
          title: "Nos Services",
          why_choose: "Pourquoi nous choisir",
          text: "Tirer parti des dernières technologies et de notre vaste expertise pour créer des solutions personnalisées transformatrices qui procurent un avantage concurrentiel à chaque type d’entreprise et à chaque industrie.",
          cards: {
            card_1: {
              title: "Développement Web",
              descript:
                "Leadeur dans le marché , nous sommes là pour vous livrer une expérience utilisateur web originale, dynamique et rassurante .  \n",
            },
            card_2: {
              title: "Développement Mobile",
              descript:
                "Toujours guidés par le succès de votre business , nous nous engageons à vous apporter une valeur ajoutée à votre projet mobile Android et IOS . \n",
            },
            card_3: {
              title: "Conception UX/UI",
              descript:
                "Vous avez l’opportunité avec nous de donner un sens, une émotion et une identité à vos interfaces utilisateurs .\n",
            },
            card_4: {
              title: "Consulting",
              descript:
                " Avec la vaste gamme d’expertise de notre équipe IT , on vous propose des prestations  de conseil personnalisées , rapides et avant-gardistes . \n",
            },
          },
        },
        value: {
          firstTitle: "Wi VALEURS",
          title: "Nos valeurs",
          subTitle: "Notre ADN s’inspire de :",
          text: "Nous sommes bien entourés autour un socle de valeurs qui guident toujours nos actions et également notre capitale humaine. ",
          cards: {
            card_1: {
              title: "La performance",
              description:
                "La solidarité de l’équipe est une alchimie singulière pour décrocher les résultats attendus dans les brefs délais possibles , quelle que soit la variété des objectifs tracés et la nature des défis rencontrés . \n",
            },
            card_2: {
              title: "La responsabilité",
              description:
                "La confiance et la satisfaction du client sont à l’origine d’une grande joie pour se mettre à la tâche chaque matin .  \n",
            },
            card_3: {
              title: "L’audace / la créativité",
              description:
                "Le respect de l'individualité et la recherche de la diversité , c’est notre vision qui nourrit la créativité et devient innovation .",
            },
          },
          link: "Découvrir",
        },
        portfolio: {
          firstTitle: "Wi PORTFOLIO",
          title: "Notre Portfolio",
          descript:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been thepsum is simply ummy text of the printing and typesetting industry. Lorem Ipsu…",
          show_all: "Voir tous",
        },
        clients: {
          firstTitle: "Wi CLIENTS",
          title: "Nos Clients & partenaires",
          descript: "Ils nous font confiance",
        },
        reviews: {
          firstTitle: "Wi COMMENTAIRES",
          title: "Ce qu'ils pensent de nous",
        },
        join: {
          firstTitle: "Wi REJOINDRE",
          title: "Rejoignez-nous",
        },
        vision: {
          firstTitle: "Wi VISION",
          title: "Notre Vision",
          text: "Travaille sans relâche pour devenir la référence leader en matière de développement informatique d’origine africaine dans le monde entier. Elle vise la présence au cœur du mutation technologique actuelle et d’avenir.",
        },
        review: {
          review_1:
            "Merci à Mohammed et son équipe pour leur réactivité face à mes demandes.  Ils ont efficacement travaillé sur les applications iOS et Android existantes.",
            review_2:"Le partenariat avec Wimobi s'est très bien déroulé et très professionnellement.",
            review_3:"Très bon travail terminé à l’heure et très bonne aisance de communication. Je le recommande."
        },
        contact: {
          firstTitle: "Wi CONTACT",
          title: "Vous voulez nous contacter ?",
          text: "Notre équipe vous aidera à transformer votre idée et votre vision en un projet bien exécuté avec une mise sur le marché rapide.",
          items: {
            item_1: {
              title: "Un Nouveau Projet",
              btn_text: "Démarrer un projet",
            },
            item_2: {
              title: "Un Job",
              btn_text: "Postuler",
            },
            item_3: {
              title: "Un Stage",
            },
          },
        },
        copy_right: {
          join_us: "Rejoignez-nous",
        },
      },
      mission: {
        firstTitle: "Wi MISSION",
        title: "Notre mission",
        descript:
          "Wimobi œuvre constamment à injecter de nouvelles compétences, technologies et idées dans le monde numérique afin d'accroître l’avancement technologique mondiale. .\n",
        cards: {
          card1: {
            title: "Conception",
            description:
              "La polyvalence alliée à l'expertise technique de notre équipe nous permet de vous concevoir une application originale , surprenante et efficace .",
          },
          card2: {
            title: "Mettre en marché",
            description:
              "Nous entourons les meilleurs profils afin de vous offrir une application  captivante , réactive , et  performante façonnée avec les technologies les plus adaptées .",
          },
          card3: {
            title: "Maintenance",
            description:
              "Pour booster la performance de votre application ,  votre image et votre identité visuelle , nous vous mettons à votre disposition une expertise variée . \n",
          },
        },
      },
      portfolio: {
        section_1: {
          title: {
            part1: "Nous avons de la chance de s'engager",
            part2: "dans des projets",
            part3: "impressionnantes",
          },
          descript: "Découvrez une sélection de nos projets",
        },
        cards: {
          card1: {
            description:
              "est une plateforme web permettant une mise en relation directe et neutre entre les clients et les prestataires du domaine IT par l’intermédiaire d’une solution intelligente de filtrage des CV . ",
          },
          card2: {
            description:
              "est le premier site web d’enchère libre en Tunisie qui vise le changement  radicale de  pratique d’achat de consommateur en proposant des offres illimitées en bas prix",
          },
          card3: {
            description:
              "est une plateforme d’administration pour EDF (Electricité de France) qui permet la gestion des activités, des équipes, des fournisseurs …etc avec un filtrage avancé et puissant. Étant un outil d’organisation interne de la société, elle contribue largement dans la réalisation de sa vision environnementale .  ",
          },
          card4: {
            description:
              "est une plateforme Web qui offre aux conducteurs un moyen alternatif de se garer sur des places laissées vacantes dans des parkings privés (résidences, hôtels, immeubles de bureaux, …) . Elle présente une réponse efficace aux problématiques urbaines de mobilité et de développement durable.",
          },
          card5: {
            description:
              "une banque en ligne dans deux versions web et mobile qui offre à ses clients un vaste éventail de services financiers.",
          },
          card6: {
            description:
              "une application mobile de transport intelligent qui offre aux citoyens tunisiens des prestations de livraison et de transport rapide et moins chères .",
          },
          card7: {
            description:
              "renvoie à une application mobile  à caractère financière qui offre des prestations bancaires à la fois pour les salariés et les entreprises . \n",
          },
          card8: {
            description:
              "est une application mobile connectée à des semelles intelligentes ayant pour finalité la détection des maladies et des anomalies de corps humain de la tête aux pieds.\n",
          },
        },
      },
      environment: {
        section_1: {
          title1: "Se réunir est un début,",
          title2: "rester ensemble est un progrès,",
          title3: "travailler ensemble est la réussite.",
          descript:
            "Notre projet consiste à lancer une aventure professionnelle où chaque collaborateur peut, soit entamer sa carrière professionnelle avec tous les atouts en main, soit acquérir des nouvelles compétences durant sa voie d’évolution.\n",
        },
        section_2: {
          firstTitle: "Wi Environnement",
          title: "TEAM BUILDING",
          descript:
            "Les femmes et les hommes qui font vivre Wimobi sont sans doute son atout principal et sa richesse. Leur motivation et leur satisfaction sont des inputs précieux pour la construction d’un environnement productif et sain au sein de notre entreprise.",
          cards: {
            card_1: {
              title: "Un management de proximité",
              description:
                "Permet d’orchestrer les besoins de chaque collaborateur et le partage de savoir-faire dans l’équipe. ",
            },
            card_2: {
              title: "Une mobilité interne ",
              description:
                "Tout à fait connectée aux ambitions et aspirations de chaque collaborateur et aux réalités du terrain.",
            },
            card_3: {
              title: "challenges et formations",
              description:
                "Les leviers incontournables pour lancer notre capital humain dans le cheminement de  productivité et d’évolution  professionnelle . \n",
            },
            card_4: {
              title: "sorties de team buildings",
              description:
                "Les bons moments d’expression et d’inspiration pour toute l’équipe.\n",
            },
          },
        },
        section_3: {
          title: "NOTRE ÉQUIPE",
          title_1: "Découvrir notre environnement",
        },
      },
      recruitment: {
        section_1: {
          title1: "Rejoignez nous sur le chemin",
          part2: "du succès",
          txt: "Ne pensez pas à l’échec, pensez aux opportunités que vous risquez de manquer si vous n’essayez pas.",
          title2: "OFFRE D’EMPLOI",
        },
        section_2: {
          title1: "Nous vous accueillons et vous encourageons",
          part2: "à vous améliorer",
          txt: "Le succès arrive toujours lorsqu’une opportunité rencontre la préparation.",
          title2: "OFFRE D’EMPLOI",
        },
        process_job: {
          firstTitle: "Wi Recrutement",
          title: "Processus d’embauche",
          text1:
            "donne l’opportunité à chaque jeune talentueux de montrer son savoir, son savoir-faire, et son savoir-être.\n" +
            "Pour trouver ce trio indispensable , nous procédons à un processus de recrutement composé de deux étapes essentielles",
          text2:
            "En fonction des résultats de tests de candidats, de ses demandes techniques, de son projet de vie, de son équilibre vie privée / vie professionnelle, un programme spécifique est établi. \n",
          items: {
            process1: "Un entretien RH",
            process2: "Un entretien technique",
            process3: "Un test technique",
          },
        },
        internship_section: {
          title2: "OFFRE DE STAGE",
        },
        process_internship: {
          firstTitle: "Wi Recrutement",
          title: "Processus De STAGE",
          text1:
            "En tant que les collaborateurs de futur, les étudiants sont invités à rejoindre notre équipe dans deux occasions particulières, soit au moment de stage de projet de fin d’étude, soit au moment de stage d’été . Dans les deux cas , une campagne de recrutement se lance chaque année pour attirer les meilleurs talents dans les campus universitaires .",
          text2:
            "Après avoir passé un entretien avec le département RH , le candidat présélectionné doit passer un test technique afin d’évaluer sa connaissance  dans la technologie choisie et sa détermination pour réaliser un projet individuel ou collectif .\n" +
            "Une fois accepté, notre stagiaire entre dans un cycle de formation sous la supervision d’un encadrement professionnel de notre équipe . ",
          items: {
            process1: "Entretien RH",
            process2: "Un test technique ",
            process3: "Un cycle de formation",
          },
        },
      },
      about_us: {
        video: {
          success: "Succès",
          title2: "Nous prospérons ensemble pour réussir",
          txt: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been thepsum is simply ummy text of the printing and typesetting industry. Lorem Ipsu…",
        },
        who_are_we: {
          firstTitle: "Wi MOBI",
          title: "QUI SOMMES-NOUS ?",
          txt1: "Créé en 2017 , Wimobi est une agence digitale internationale qui apporte son expertise et son savoir-faire dans 3 domaines: le Web , le mobile et le design.",
          txt2: "Avec une culture de l’innovation au cœur de son ADN , Wimobi est forte de plusieurs trentaines de projets et de 30 talents et experts métier. Elle est présente à Mahdia , Monastir et Paris.",
        },
        history: {
          firstTitle: "Wi HISTOIRE ",
          title: "Notre Histoire",
          txt: "Wimobi est une agence web et mobile créée en Septembre 2017. Elle fournit des solutions web mobiles et design sur mesure pour le marché tunisien, français, canadien et du pays de Golf.\n",
          soon: "Bientôt",
          title2: "Au bout de 5 ans",
          txt1: " a bien évolué . La première pierre a été posée  en 2017  à Mahdia. Durant cette année, grâce à notre large spectre d’expertises pointues, nous avons  réussi à avoir la confiance des partenaires tunisiens, français et de pays de golf.",
          txt2: "Par la suite , pour mieux approcher  les meilleurs profils et les esprits créatifs issues des campus universitaires , nos portes ont été ouvertes en début 2022 à Monastir .\n",
          txt3: "Comme notre ambition n’a aucune limite , aujourd’hui Wimobi Paris, est la plus jeune filiale. Elle étend notre présence sur la continente européenne . ",
        },
      },
      contact: {
        card1: {
          title: "Un Nouveau Projet",
          nextTitle: "Un J",
          from: {
            section1: {
              title: "INFORMATIONS GÉNÉRALES",
            },
            section2: {
              title: "VOTRE BUDGET",
            },
            section3: {
              title: "VOTRE DEADLINE",
            },
            section4: {
              title: "Vos Besoins",
            },
            section5: {
              title: "Message",
            },
          },
        },
        card2: {
          prevTitle: "au Projet",
          title: "Un Job",
          nextTitle: "Un S",
          from: {
            section1: {
              title: "INFORMATIONS GÉNÉRALES",
            },
            section2: {
              title: "CONTRAT",
            },
            section3: {
              title: "Votre Profil",
            },
            section4: {
              title: "Message",
            },
          },
        },
        card3: {
          prevTitle: "Job",
          title: "Un Stage",
          text: "Nous Sommes À Votre Écoute !",
          from: {
            section1: {
              title: "INFORMATIONS GÉNÉRALES",
            },
            section2: {
              title: "CONTRAT",
            },
            section3: {
              title: "Type De Stage",
            },
            section4: {
              title: "Votre Profil",
            },
            section5: {
              title: "Message",
            },
          },
        },
        forms: {
          needs: {
            label_1: "Développement",
            label_2: "UX Design",
            label_3: "Autres",
            label_4: "Développement web",
            label_5: "UI Design",
            label_6: "Développement mobile",
            label_7: "UX/UI Design",
          },
          message: {
            label: "Bonjour madame/monsieur",
          },
          contrat: {
            type_1: "CDD-CDI",
            type_2: "Alternance",
            type_3: "Développement mobile",
            type_4: "Pré-Embauche",
            type_5: "Stage",
          },
          profil: {
            profil_1: "Chef de projet",
            profil_2: "Développeur web",
            profil_3: "UX/UI Designer",
            profil_4: "Front-end",
            profil_5: "Développeur Mobile",
            profil_6: "Développeur IOS",
            profil_7: "Back-End",
            profil_8: "Designer",
            profil_9: "Développeur ANDROID",
          },
          intership: {
            intership_1: "Stage d’été",
            intership_2: "Stage PFE",
          },
          Undetermined: "Indéterminé",
          month: "mois",
        },
        name: "Nom",
        FirstName: "Prénom",
        mail: "E-mail",
        phone: "Téléphone",
        company: "Société",
        send: "Envoyer",
        appreciation: "Merci pour votre confiance !",
        upload_cv: "Déposez votre CV",
      },
      address: {
        label_1:
          "Immeuble Prestige, 100 Av. Taieb Mhiri Mahdia, Gouvernorat de Mahdia ",
        label_2: "Centre Ville Monastir , Avenue des Martyrs, 5000 Monastir ",
        label_3: "48 RUE SARRETTE 75014, PARIS, FRANCE",
      },
    },
  },
  en: {
    translation: {
      header: {
        home: "Home",
        aboutUs: "About us",
        recruitment: "Recruitment",
        environment: "Environment",
        Portfolio: "Portfolio",
        Contact: "Contact",
        english: "English",
        french: "French",
      },
      mission: {
        firstTitle: "Wi MISSION",
        title: "Our mission",
        descript:
          "Wimobi is constantly working to bring new skills, technologies, and ideas into the digital world with the ambition of enriching global technological advancement.",
        cards: {
          card1: {
            title: "Design",
            description:
              "The polyvalence allied to the technical expertise of our team allows us to design an original, innovative and effective application.",
          },
          card2: {
            title: "production",
            description:
              "We bring together the best profiles to create for you a captivating, responsive, and high-performance application shaped with the most advanced technologies. ",
          },
          card3: {
            title: "Maintenance",
            description:
              "To boost the performance of your application, your image and your visual identity, we provide you with a wide range of expertise.",
          },
        },
      },
      home: {
        section_1: {
          txt1: "We can dream and innovate your business together",
          title_1: "Your PARTNER in ",
          title_2: "digital TRANSFORMATION",
          descript:
            "Whatever is your field  , your needs , your targets , we offer you limitless possibilities for your web , mobile and design solutions .",
          btn: "Start a project",
        },
        static: {
          years: "years",
          experience: "Experience",
          clients: "Clients",
          projects: "Projects",
          employees: "Employees",
        },
        aboutSection: {
          firstTitle: "Wi MOBI",
          title: "About us",
          created: "Created in 2017",
          text: " , Wimobi is an international digital agency that offers IT services at the cutting edge technologies and the most innovating solutions.",
          btn: "Discover more",
        },
        environment: {
          firstTitle: "Wi ENVIRONMENT",
          title: "Within Wimobi",
        },
        service: {
          firstTitle: "Wi SERVICES",
          title: "Our services",
          why_choose: "Why choose us",
          text: "Leverage the latest technologies and our extensive expertise to create transformative custom solutions that deliver competitive advantage to every type of business and every industry.",
          cards: {
            card_1: {
              title: "Web",
              descript:
                "As the leading company in the market, we are here to deliver an original, dynamic, and reassuring user experience for you.",
            },
            card_2: {
              title: "Mobile",
              descript:
                "Always driven by the success of your business, we are committed to bringing added value to your Android and IOS mobile project.",
            },
            card_3: {
              title: "UX/ UI design",
              descript:
                "With us you have the opportunity to give a meaning, an emotion, and an identity to your user interfaces.",
            },
            card_4: {
              title: "Consulting",
              descript:
                "With the broad range of expertise of our IT team, we offer you personalized, fast, and cutting-edge consulting services. ",
            },
          },
        },
        value: {
          firstTitle: "Wi VALUES",
          title: "our values",
          subTitle: "Our DNA is based on : ",
          text: "Our deep-seated values always guide our actions as well as our human capital.",
          cards: {
            card_1: {
              title: "Performance",
              description:
                "Teamwork is a unique formula to achieve the expected results in record time regardless of the variety of the outlined objectives and the nature of the faced challenges.",
            },
            card_2: {
              title: "Responsibility",
              description:
                "Our customer's confidence and satisfaction are the driving force behind our motivation to give our all.",
            },
            card_3: {
              title: "Audacity / Creativity",
              description:
                "The respect for individuality and diversity is the vision that fuels our creative minds and drives them towards innovation.",
            },
          },
          link: "Discover",
        },
        portfolio: {
          firstTitle: "Wi PORTFOLIO",
          title: "Our Portfolio",
          descript:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been thepsum is simply ummy text of the printing and typesetting industry. Lorem Ipsu…",
          show_all: "Show all",
        },
        clients: {
          firstTitle: "Wi CLIENTS",
          title: "Our Clients & Partners",
          descript: "They trust us",
        },

        reviews: {
          firstTitle: "Wi REVIEWS",
          title: "What they think about us",
        },
        join: {
          firstTitle: "Wi JOIN",
          title: "Join us",
        },
        vision: {
          firstTitle: "Wi VISION",
          title: "Our vision",
          text: "Wimobi is relentlessly striving to become the African benchmark for IT development worldwide. It aims to be at the heart of the current and the future technological transformation.",
        },
        review: {
          review_1:
            "Thank you to Mohamed and his entire team for their responsiveness to my requests. They have efficiently worked on the current IOS and Android applications.",
            review_2:"The partnership with WIMOBI worked out perfectly well and very professionally.",
            review_3:"Very good job delivered on time and excellent communication skills. I recommend it ."
        },
        contact: {
          firstTitle: "Wi CONTACT",
          title: "Do you want to contact us ?",
          text: "Our team will help you turn your idea and vision into a well-executed project with rapid time to market.",
          items: {
            item_1: {
              title: "A New Project",
              btn_text: "Start a project",
            },
            item_2: {
              title: "A Job",
              btn_text: "To apply",
            },
            item_3: {
              title: "An Internship",
            },
          },
        },
        copy_right: {
          join_us: "Join us",
        },
      },
      portfolio: {
        section_1: {
          title: {
            part1: "We are lucky to be involved",
            part2: "in some impressive",
            part3: "projects",
          },
          descript: "Discover a selection of our projects",
        },
        cards: {
          card1: {
            description:
              "is a web platform allowing direct and neutral contact between customers and service providers in the IT field through an intelligent CV filtering solution.",
          },
          card2: {
            description:
              "is the first free auction website in Tunisia which aims to radically change consumer purchasing practices by offering unlimited offers at low prices",
          },
          card3: {
            description:
              "is an administration platform for EDF (Electricité de France) which allows the management of activities, teams, suppliers, etc. with advanced and powerful filtering. Being a tool for the internal organization of the company, it largely contributes to the realization of its environmental vision.",
          },
          card4: {
            description:
              "is a web platform that offers drivers an alternative way to park in places left vacant in private car parks (residences, hotels, office buildings, etc.). It presents an effective response to urban issues of mobility and sustainable development.",
          },
          card5: {
            description:
              "an online bank in two web and mobile versions that offers its customers a wide range of financial services.",
          },
          card6: {
            description:
              "an intelligent transport mobile application that offers Tunisian citizens fast and cheaper delivery and transport services.",
          },
          card7: {
            description:
              "refers to a financial mobile application that offers banking services for both employees and businesses.",
          },
          card8: {
            description:
              "a mobile application connected to smart soles whose purpose is to detect diseases and anomalies of the human body from head to toe.",
          },
        },
      },
      environment: {
        section_1: {
          title1: "Coming together is a beginning,",
          title2: "staying together is progress,",
          title3: "working together is success.",
          descript:
            "Our project is about launching a professional adventure where each employee can either start their professional career with all their assets in hand or acquire new skills during the course of their journey with us.",
        },
        section_2: {
          firstTitle: "Wi Environment",
          title: "TEAM BUILDING",
          descript:
            "Our employees are undoubtedly Wimobi's main asset and its wealth. Their motivation and satisfaction are valuable inputs for the construction of a productive and healthy environment within our company.",
          cards: {
            card_1: {
              title: "Proximity management",
              description:
                "Allows us to orchestrate the needs of each employee and knowledge   sharing  between the different members of the team.",
            },
            card_2: {
              title: "Challenges and training",
              description:
                "Are key levers to launch our human capital on the path to productivity and professional development .",
            },
            card_3: {
              title: "Internal mobility",
              description:
                "Is fully connected to the ambitions and aspirations of each employee and the realities of the field.",
            },
            card_4: {
              title: "Team-building outings",
              description:
                "That offer the space for expression and inspiration for the entire team.",
            },
          },
        },
        section_3: {
          title: "OUR TEAM",
          title_1: "Discover our environment",
        },
      },
      recruitment: {
        section_1: {
          title1: "Climb the ladder of success",
          part2: "with us",
          txt: "Don't worry about failures, worry about the chances you miss when you don't even try.",
          title2: "JOB OFFER",
        },
        section_2: {
          title1: "We take you in and nurture",
          part2: "you to improve",
          txt: "Success always happens when an opportunity meets preparation.",
          title2: "JOB OFFER",
        },
        process_job: {
          firstTitle: "Wi Recruitment",
          title: "Hiring process",
          text1:
            "offers every talented young person the opportunity to showcase their knowledge, their expertise, and their soft skills.\n" +
            "In order to identify this essential trio, we carry out a recruitment process consisting of two key stages: an HR interview and a technical interview.",
          text2:
            "After which a specific program will be established according to the results of the candidates' tests, their technical requirements,and their work/life balance.",
          items: {
            process1: "An HR interview",
            process2: "A technical interview",
            process3: "A technical test",
          },
        },
        internship_section: {
          title2: "INTERNSHIP OFFER",
        },
        process_internship: {
          firstTitle: "Wi Recruitment",
          title: "Internship process",
          text1:
            "As future collaborators, students are invited to join our team on two particular occasions, either upon the time of their end-of-study project internship, or during summer. In both cases, a recruitment campaign is launched every year to attract the best talents of university campuses.",
          text2:
            "After passing an interview with the HR department, the pre-selected candidates must pass a technical test in order to evaluate their knowledge in the chosen technology in addition to their determination to realize an individual or a collective project.\n" +
            "Once accepted, our trainee enters a training cycle under the supervision of a professional team.",
          items: {
            process1: "HR interview",
            process2: "A technical test",
            process3: "A training cycle",
          },
        },
      },
      about_us: {
        video: {
          success: "success",
          title2: "We prosper together for success",
          txt: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been thepsum is simply ummy text of the printing and typesetting industry. Lorem Ipsu…",
        },
        who_are_we: {
          firstTitle: "Wi MOBI",
          title: "WHO ARE WE ?",
          txt1: "Created in 2017, Wimobi is an international digital agency that brings its expertise and know-how in 3 areas: Web, mobile and design.\n",
          txt2: " With a culture of innovation at the heart of its DNA, Wimobi has a track record of over 30 challenging projects and more than 30 talents and experts. It operates in Mahdia, Monastir and Paris .",
        },
        history: {
          firstTitle: "Wi HISTORY ",
          title: "Our history",
          txt: "Wi mobi is a web and mobile agency created in September 2017. It provides mobile & web solutions and custom designs for our clients.",
          soon: "Soon",
          title2: "After 5 years",
          txt1: "When it was first established in 2017 in Mahdia it managed to gain the confidence of partners in Tunisia, France, Canada and several Gulf countries,. Ever since, Wimobi has been continually evolving.",
          txt2: "Subsequently, and to attract the top talents, we opened a new office located in Monastir by the beginning of 2022. ",
          txt3: "As our ambition has no limits, today Wi mobi Paris, is the youngest subsidiary extending our presence in the European continent. ",
        },
      },
      contact: {
        card1: {
          title: "A New Project",
          nextTitle: "A Jo",
          from: {
            section1: {
              title: "GENERAL INFORMATIONS",
            },
            section2: {
              title: "YOUR BUDGET",
            },
            section3: {
              title: "YOUR DEADLINE",
            },
            section4: {
              title: "Your needs",
            },
            section5: {
              title: "Message",
            },
          },
        },
        card2: {
          prevTitle: "Project",
          title: "A Job",
          nextTitle: "Internship",
          from: {
            section1: {
              title: "GENERAL INFORMATIONS",
            },
            section2: {
              title: "CONTRACT",
            },
            section3: {
              title: "Your profile",
            },
            section4: {
              title: "Message",
            },
          },
        },
        card3: {
          prevTitle: "Job",
          title: "An Internship",
          text: "We are listening to you !",
          from: {
            section1: {
              title: "GENERAL INFORMATIONS",
            },
            section2: {
              title: "CONTRACT",
            },
            section3: {
              title: "Internship Type",
            },
            section4: {
              title: "Your profile",
            },
            section5: {
              title: "Message",
            },
          },
        },
        forms: {
          needs: {
            label_1: "Development",
            label_2: "UX Design",
            label_3: "Others",
            label_4: "Web development",
            label_5: "UI Design",
            label_6: "Mobile development",
            label_7: "UX/UI Design",
          },
          message: {
            label: "Hello Miss/Sir",
          },
          contrat: {
            type_1: "CDD-CDI",
            type_2: "Alternation",
            type_3: "Mobile development",
            type_4: "Pre-hire",
            type_5: "Intership",
          },
          profil: {
            profil_1: "Project Manager",
            profil_2: "Web developer",
            profil_3: "UX/UI Designer",
            profil_4: "Front-end",
            profil_5: "Mobile Developer",
            profil_6: "IOS Developer",
            profil_7: "Back-End",
            profil_8: "Designer",
            profil_9: "ANDROID Developer",
          },
          intership: {
            intership_1: "Summer internship",
            intership_2: "PFE internship",
          },
          Undetermined: "Undetermined",
          month: "months",
        },
        name: "Last name",
        FirstName: "First name",
        mail: "Email",
        phone: "Phone",
        company: "Company",
        send: "Send",
        appreciation: "Thanks for your confidence!",
        upload_cv: "Upload your CV",
      },
      address: {
        label_1:
          "Prestige building , Avenue Taieb Mhiri , 5111 ,  Mahdia city ",
        label_2:
          "EL Bacha building , Monastir Downtown , Martyrs avenue , 5000 , Monastir ",
        label_3: "Avenue 48 , Sarrette avenue 75014 , Paris 14",
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "fr",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
