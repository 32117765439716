import React, {useState} from 'react';
import "./CardsPortfolio.scss";
import CardPortfolio from "../card-portfolio/CardPortfolio";
import img1 from "../../assets/images/img-portf.png";
import img2 from "../../assets/images/zid-img.png"
import img3 from "../../assets/images/edfImg.png";
import img4 from "../../assets/images/zen-park.png";
import img5 from "../../assets/images/fintchImg.png";
import img6 from "../../assets/images/intigoImg.png";
import img7 from "../../assets/images/paiedImg.png";
import img8 from "../../assets/images/digitsoleImg.png";
import {useTranslation} from "react-i18next";
import "../../assets/i18n";

const CardsPortfolio = () => {
    const {t, i18n} = useTranslation();
    const [isOpen1, setIsOpen1] = useState(true);
    const [isOpen2, setIsOpen2] = useState<boolean>();

    const open1 = () => {
        setIsOpen1(true);
        setIsOpen2(false);
        window.scrollTo(0, 0);

    }
    const open2 = () => {
        setIsOpen2(true);
        setIsOpen1(false);
        window.scrollTo(0, 0);
    }

    const dataFirstCards = [
        {
            title: "IT Matched",
            img: img1,
            descript: t('portfolio.cards.card1.description'),
            isRight: false,
            className: "",
            link : "https://itmatched.com"
        },
        {
            title: "ZiD.Tn",
            img: img2,
            descript: t('portfolio.cards.card2.description'),
            link: "https://zid.tn/comment_ca_marche",
            isRight: true,
            className: ""
        },
        {
            title: "EDF",
            img: img3,
            descript: t('portfolio.cards.card3.description'),
            isRight: false,
            link: "www.edf.fr",
            className: ""
        },
        {
            title: "Zen Park",
            img: img4,
            descript: t('portfolio.cards.card4.description'),
            link: "https://zenpark.com/",
            isRight: true,
            className: "",
        },
    ]

    const dataSecondCards = [
        {
            title: "Fintch",
            img: img5,
            descript: t('portfolio.cards.card5.description'),
            isRight: false,
            link: "https://fintch.fr/",
            className: ""
        },
        {
            title: "Intigo",
            img: img6,
            descript: t('portfolio.cards.card6.description'),
            link: "https://intigo.tn",
            isRight: true,
            className: "mob-width"
        },
        {
            title: "Paied",
            img: img7,
            descript: t('portfolio.cards.card7.description'),
            isRight: false,
            link: "https://paied.co/home",
        },
        {
            title: "Digitsole",
            img: img8,
            descript: t('portfolio.cards.card8.description'),
            link: "https://digitsole.com/",
            isRight: true,
            className: "mob-width"
        },
    ]
    return (
        <div className="content-portfolio">


            {(isOpen1 ? dataFirstCards : dataSecondCards).map(item => {
                return (
                    <CardPortfolio isRight={item.isRight} img={item.img} title={item.title}
                                   descript={item.descript}
                                   link={item.link} className={item.className}/>
                )
            })}


            <div className="container">
                <div className="blc-bttns center-horizontally">
                    <button className={` ${isOpen1 && "selected"}`} onClick={open1}>1</button>
                    <button className={` ${isOpen2 && "selected"}`} onClick={open2}>2</button>
                </div>
            </div>
        </div>
    );
};

export default CardsPortfolio;
