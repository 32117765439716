import React, {useEffect, useRef} from 'react';
import "./Vision.scss";
import TitleSection from "../title-section/TitleSection";
import {useTranslation} from "react-i18next";
import "../../assets/i18n";
import imgDesk from "../../assets/images/img-vision.png";
import imgMob from "../../assets/images/visionBGmob.png";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const Vision = () => {
    const {t, i18n} = useTranslation();
    const visionRef = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.fromTo("#title-vision", {
            y: -100,
            opacity:0,
        }, {
            y: 0,
            opacity:1,
            duration: 2,
            delay: 1,
            scrollTrigger: {
                trigger: "#title-vision",
                start: "top 50%",
            }
        });

        gsap.fromTo("#vision-content", {
            y: 200,
            opacity:0,
        }, {
            y: 0,
            opacity:1,
            duration: 2,
            delay: 1,
            scrollTrigger: {
                trigger: "#vision-content",
            }
        });
    }, []);
    return (
        <div className="vision" ref={visionRef}>
            <div className="container">
                <div className="center-horizontally justify-center" >
                    <div id="title-vision">
                        <TitleSection title={t('home.vision.title')} firstTitle={t('home.vision.firstTitle')}/>
                    </div>
                </div>
                <div className="vision-content" id="vision-content">
                    <img className="imgDesk" src={imgDesk} alt="img"/>
                    <img className="imgMob" src={imgMob} alt="img"/>
                    <div className="absolute-txt">
                        <div className="title-blc">
                            <h1>Wimobi</h1>
                            <div className="title-abs">
                                <h1>Wimobi</h1>
                            </div>
                        </div>
                        <p>{t('home.vision.text')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Vision;
