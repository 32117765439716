import React, {useEffect, useRef} from 'react';
import "./VideoSection.scss";
import {useTranslation} from "react-i18next";
import "../../assets/i18n";
import icn from "../../assets/icons/flech-internship.svg";
import ReactPlayer from "react-player";
import cover from "../../assets/images/cover.png";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";


const VideoSection = () => {
    const {t} = useTranslation();

    gsap.registerPlugin(ScrollTrigger);
    const refVideo = useRef(null);


    useEffect(() => {
        gsap.fromTo("#first-title", {
            y: -50,
            opacity: 0,
        }, {
            y: 0,
            delay:0.5,
            opacity: 1,
            duration: 1,
            scrollTrigger: {
                trigger: "#first-title",
            }
        });
        gsap.fromTo(".abs-blc", {
            y: 50,
            opacity: 0,
        }, {
            y: 0,
            delay: 1,
            opacity: 1,
            duration: 1,
            scrollTrigger: {
                trigger: ".abs-blc",
            }
        });
        // gsap.fromTo("#txt", {
        //     y: 50,
        //     opacity: 0,
        // }, {
        //     y: 0,
        //     opacity: 1,
        //     duration: 1,
        //     delay: 1,
        //     scrollTrigger: {
        //         trigger: "#txt",
        //     }
        // });

        gsap.fromTo(".flech", {
            y: -50,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 1,
            delay: 0.5,
            scrollTrigger: {
                trigger: ".flech",
            }
        });

        gsap.fromTo("#vid-sect", {
            y: 100,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 1,
            delay: 0.5,
            scrollTrigger: {
                trigger: "#vid-sect",
            }
        });


    }, []);

    return (
        <div className="video-section">
            <div className="container" ref={refVideo}>
                <div className="title-video">
                    <h1 className='success-title' id="first-title">
                        {t('about_us.video.success')}
                    </h1>
                    <div className="abs-blc">
                        <h1>
                            {t('about_us.video.title2')}
                        </h1>
                    </div>
                </div>
                <div className="content-video">
                    {/* <p>
                        <img className="flech" src={icn}
                             alt="icon"/></p>

                    <div className="blc-video" id="vid-sect">
                       
                        <ReactPlayer
                            url='video.mp4'
                            light={cover}
                            width="100%"
                            height="100%"
                            playing={true}
                            controls={true}
                        />
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default VideoSection;
