import React from 'react';
import Header from "../../components/header/Header";
import Contact from "../../components/contact/Contact";
import CopyRight from "../../components/copy-right/CopyRight";
import EnvFirstSection from "../../components/env-first-section/EnvFirstSection";
import EnvSecondSection from "../../components/env-second-section/EnvSecondSection";
import EnvThirdSection from "../../components/env-third-section/EnvThirdSection";

const EnvironmentPage = () => {
    return (
        <div className="environment-page">
            <Header />
            <EnvFirstSection />
            <EnvSecondSection />
            <EnvThirdSection />
            <Contact />
            <CopyRight />
        </div>
    );
};

export default EnvironmentPage;
