import React, {useEffect, useRef} from 'react';
import "./Mission.scss";
import CardValue from "../card-value/CardValue";
import img from "../../assets/images/img-mission.png";
import {useTranslation} from "react-i18next";
import "../../assets/i18n";
import icn1 from "../../assets/icons/icn1-m.svg";
import icn2 from "../../assets/icons/icn2-m.svg";
import icn3 from "../../assets/icons/icn4-m.svg";
import TitleSection from "../title-section/TitleSection";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const Mission = () => {
    const {t, i18n} = useTranslation();
    const missionRef = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.fromTo("#blc-left", {
            x: -500,
            opacity:0,
        }, {
            x: 0,
            opacity:1,
            duration: 2,
            delay: 1,
            scrollTrigger: {
                trigger: "#blc-left",
                start: "top 50%",
            }
        });
        gsap.fromTo("#blc-img", {
            x: 500,
            opacity:0,
        }, {
            x: 0,
            opacity:1,
            duration: 2,
            delay:1,
            scrollTrigger: {
                trigger: "#blc-img",
                start: "top 50%",
            }
        });
    }, []);
    const dataValue = [
        {
            icn: icn1,
            title: t('mission.cards.card1.title'),
            text: t('mission.cards.card1.description')
        },
        {
            icn: icn2,
            title: t('mission.cards.card2.title'),
            text: t('mission.cards.card2.description')
        },
        {
            icn: icn3,
            title: t('mission.cards.card3.title'),
            text: t('mission.cards.card3.description')
        },


    ]
    return (
        <div className="mission">
            <div className="mission-content center-horizontally space-between" ref={missionRef}>
                <div className="blc-left" id="blc-left">
                    <TitleSection title={t('mission.title')} firstTitle={t('mission.firstTitle')}/>
                    <div className="blc-text">
                        <p>{t('mission.descript')}</p>
                    </div>
                    <div className="cards-blc center-horizontally align-center space-between">
                        {
                            dataValue.map(item => {
                                return (
                                    <CardValue icn={item.icn} title={item.title} text={item.text}/>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="blc-img" id="blc-img">
                    <img src={img} alt=""/>
                </div>
            </div>
        </div>
    );
};

export default Mission;
