import React, {useEffect, useRef} from 'react';
import "./Clients.scss";
import TitleSection from "../title-section/TitleSection";
import {useTranslation} from "react-i18next";
import "../../assets/i18n";
import client1 from "../../assets/images/clients/client1.png";
import client2 from "../../assets/images/clients/client2.png";
import client3 from "../../assets/images/clients/client3.png";
import client4 from "../../assets/images/clients/client4.png";
import client5 from "../../assets/images/clients/client5.png";
import client6 from "../../assets/images/clients/client6.png";
import client7 from "../../assets/images/clients/client7.png";
import client8 from "../../assets/images/clients/client8.png";
import client9 from "../../assets/images/clients/client9.png";
import client10 from "../../assets/images/clients/client10.png";
import client11 from "../../assets/images/clients/client11.png";
import {Swiper, SwiperSlide} from "swiper/react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";


const dataClients = [
    {
        class: "client1",
        src: client1
    },
    {
        class: "client2",
        src: client2
    },
    {
        class: "client3",
        src: client3
    },
    {
        class: "client4",
        src: client4
    },
    {
        class: "client5",
        src: client5
    },
    {
        class: "client6",
        src: client6
    },
    {
        class: "client7",
        src: client7
    },
    {
        class: "client8",
        src: client8
    },
    {
        class: "client9",
        src: client9
    },
    {
        class: "client10",
        src: client10
    },
    {
        class: "client11",
        src: client11
    },

]


const Clients = () => {
    const {t, i18n} = useTranslation();

    gsap.registerPlugin(ScrollTrigger);
    const clientRef = useRef(null);


    useEffect(() => {
        gsap.fromTo("#title-client", {
            y: -100,
            opacity:0,

        }, {
            y: 0,
            opacity:1,
            duration: 1,
            delay: 2,
            scrollTrigger: {
                trigger: "#title-client",
                start: "top 50%",
            }
        });
        gsap.fromTo("#descript-txt", {
            y: 100,
            opacity:0,
        }, {
            y: 0,
            opacity:1,
            duration: 1,
            delay: 2,
            scrollTrigger: {
                trigger: ".clients",
                start: "top 50%",
            }
        });
        gsap.fromTo(".list-client", {
            scale: 4,
            opacity:0,

        }, {
            scale: 1,
            opacity:1,
            duration: 1,
            delay: 1,
            scrollTrigger: {
                trigger: ".clients",
                start: "top 50%",
            }
        });
    }, []);

    return (
        <div className="clients" ref={clientRef}>
            <div className="container" >
                <div className="center-vertically align-center">
                    <div id="title-client">
                        <TitleSection title={t('home.clients.title')} firstTitle={t('home.clients.firstTitle')}/>
                    </div>
                    <p className="descript-c" id="descript-txt">
                        {t('home.clients.descript')}
                    </p>
                </div>
                <div className="list-client">
                    <Swiper
                        slidesPerView={"auto"}
                        spaceBetween={30}
                        loop={true}
                        className="mySwiper"
                    >
                    {
                        dataClients.map( img => {
                            return (
                                <SwiperSlide>
                                <img src={img.src} alt="img" className={img.class}/>
                                </SwiperSlide>
                            )
                        })
                    }
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default Clients;
