import React from 'react';
import "./TitleSection.scss";

interface Props {
    firstTitle: string;
    title: string;
}

const TitleSection = (Props: Props) => {
    return (
        <div className="title-section">
            <p className="center-horizontally align-center">{Props.firstTitle}</p>
            <h1>
                {Props.title}
            </h1>
        </div>
    );
};

export default TitleSection;
