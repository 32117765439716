import React from 'react';
import "./PortfolioPage.scss";
import Header from "../../components/header/Header";
import PortfolioFirstSection from "../../components/portfolio-first-section/PortfolioFirstSection";
import Clients from "../../components/client/Clients";
import Contact from "../../components/contact/Contact";
import CopyRight from "../../components/copy-right/CopyRight";
import CardsPortfolio from "../../components/cards-portfolio/CardsPortfolio";

const PortfolioPage = () => {
    return (
        <div>
            <Header/>
            <PortfolioFirstSection/>
            <CardsPortfolio />
            <Clients />
            <Contact />
            <CopyRight />
        </div>
    );
};

export default PortfolioPage;
