import React, { Fragment, useState } from "react";
import "./Forms.scss";
import TitleContact from "../../../components/title-contact/TitleContact";
import { useTranslation } from "react-i18next";
import "../../../assets/i18n";
import Input from "../../../components/input/Input";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Slider } from "@mui/material";
import axios from "axios";
import { PATHS } from "../../../core/enums/Paths";
import { toast } from "react-toastify";

const Form1 = () => {
  const { t, i18n } = useTranslation();
  const [budgetValue, setBudgetValue] = useState<number>();
  const [deadline, setDeadline] = useState<number>();
  const [valueNeeds, setValueNeeds] = useState("");
  const [isLimitBudget, setIsLimitBudget] = useState("");
  const [isLimitDuration, setIsLimitDuration] = useState("");
  const [dataInputs, setDataInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  });

  const handleChangeBudget = (event: Event, newValue: number | number[]) => {
    console.log(event, newValue);
    if (typeof newValue === "number") {
      setBudgetValue(newValue);
    }
  };
  const handleChangeDeadline = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setDeadline(newValue);
    }
  };

  const onChangeInputs = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDataInputs({
      ...dataInputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueNeeds((event.target as HTMLInputElement).value);
  };
  const handleChangeLimitBudget = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsLimitBudget((event.target as HTMLInputElement).value);
  };
  const handleChangeLimitDuration = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsLimitDuration((event.target as HTMLInputElement).value);
  };

  const budget = [
    {
      value: 0,
      label: "1000 DT",
    },
    {
      value: 25,
      label: "5000 DT",
    },
    {
      value: 50,
      label: "10000 DT",
    },
    {
      value: 75,
      label: "15000 DT",
    },
    {
      value: 100,
      label: "20000 DT",
    },
  ];

  const duration = [
    {
      value: 0,
      label: "-",
    },
    {
      value: 25,
      label: `2 ${t("contact.forms.month")}`,
    },
    {
      value: 50,
      label: `3 ${t("contact.forms.month")}`,
    },
    {
      value: 75,
      label: `4 ${t("contact.forms.month")}`,
    },
    {
      value: 100,
      label: `5 ${t("contact.forms.month")}`,
    },
  ];

  function valuetextbudget(value: number) {
    return `${value} DT`;
  }
  function valuetextduration(value: number) {
    return `${value} ${t("contact.forms.month")}`;
  }

  const dataForms = [
    {
      id: 1,
      title: t("contact.card1.from.section1.title"),
      nbr: "01",
      isInput: true,
      form: [
        {
          text: "*" + t("contact.name"),
          type: "text",
          name: "firstName",
          value: dataInputs.firstName,
        },
        {
          text: "*" + t("contact.FirstName"),
          type: "text",
          name: "lastName",
          value: dataInputs.lastName,
        },
        {
          text: "*" + t("contact.mail"),
          type: "email",
          name: "email",
          value: dataInputs.email,
        },
        {
          text: "*" + t("contact.phone"),
          type: "text",
          name: "phone",
          value: dataInputs.phone,
        },
        {
          text: "*" + t("contact.company"),
          type: "text",
          name: "company",
          value: dataInputs.company,
        },
      ],
    },
    {
      id: 2,
      title: t("contact.card1.from.section2.title"),
      nbr: "02",

      form: [
        {
          isBudget: true,
        },
      ],
    },
    {
      id: 3,
      title: t("contact.card1.from.section3.title"),
      nbr: "03",

      form: [
        {
          isDeadline: true,
        },
      ],
    },
    {
      id: 4,
      title: t("contact.card1.from.section4.title"),
      nbr: "04",
      isRadio: true,
      form: [
        {
          label: t("contact.forms.needs.label_1"),
        },
        {
          label: t("contact.forms.needs.label_2"),
        },
        {
          label: t("contact.forms.needs.label_3"),
        },
        {
          label: t("contact.forms.needs.label_4"),
        },
        {
          label: t("contact.forms.needs.label_5"),
        },
        {
          label: t("contact.forms.needs.label_6"),
        },
        {
          label: t("contact.forms.needs.label_7"),
        },
      ],
    },
    {
      id: 5,
      title: t("contact.card1.from.section5.title"),
      nbr: "05",
      form: [
        {
          labelArea: t("contact.forms.message.label"),
          name: "message",
          value: dataInputs.message,
          type: "text",
        },
      ],
    },
  ];

  const onSubmit = async () => {
    const body = new FormData();
    body.append("firstName", dataInputs.firstName);
    body.append("lastName", dataInputs.lastName);
    body.append("email", dataInputs.email);
    body.append("phone", dataInputs.phone);
    body.append("company", dataInputs.company);
    body.append("subject", "projet");
    body.append(
      "budget",
      (isLimitBudget ? isLimitBudget : budgetValue) as string
    );
    body.append(
      "deadline",
      (isLimitDuration ? isLimitDuration : deadline) as string
    );
    body.append("requirement", valueNeeds);
    body.append("message", dataInputs.message);
    await axios({
      method: "post",
      url: PATHS.API_CONTACT,
      data: body,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (response.status === 200) {
          toast.success("Votre message a été envoyé avec succèss");
          setDataInputs({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            company: "",
            message: "",
          });
          setBudgetValue(0);
          setDeadline(0);
          setValueNeeds("");
          setIsLimitBudget("");
          setIsLimitDuration("");
        } else {
          toast.error("Veuillez vérifier vos données");
        }
      })
      .catch(function (response) {
        toast.error("Veuillez vérifier vos données");
      });
  };

  return (
    <div className="forms">
      {dataForms.map((item) => {
        return (
          <div key={item.id}>
            <TitleContact title={item.title} nbr={item.nbr} />
            <div className={`content-form ${item.isRadio && "grid"}`}>
              {item.form?.map((form: any) => {
                return (
                  <Fragment>
                    {form.text && (
                      <Input
                        onChange={onChangeInputs}
                        value={form.value}
                        name={form.name}
                        text={form.text}
                        type={form.type}
                      />
                    )}

                    {form.label && (
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={valueNeeds}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value={form.label}
                            control={<Radio />}
                            label={form.label}
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                    {form.labelArea && (
                      <Input
                        type={form.type}
                        onChange={onChangeInputs}
                        value={form.value}
                        name={form.name}
                        isTextArea
                        text={form.labelArea}
                      />
                    )}
                    {form.isBudget && (
                      <Fragment>
                        <Slider
                          aria-label="Custom marks"
                          defaultValue={0}
                          getAriaValueText={valuetextbudget}
                          step={5}
                          valueLabelDisplay="off"
                          marks={budget}
                          onChange={handleChangeBudget}
                        />
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={isLimitBudget}
                            onChange={handleChangeLimitBudget}
                          >
                            <FormControlLabel
                              value={t("contact.forms.Undetermined")}
                              control={<Radio />}
                              label={t("contact.forms.Undetermined")}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Fragment>
                    )}
                    {form.isDeadline && (
                      <Fragment>
                        <Slider
                          aria-label="Custom marks"
                          defaultValue={0}
                          getAriaValueText={valuetextduration}
                          step={5}
                          valueLabelDisplay="off"
                          marks={duration}
                          onChange={handleChangeDeadline}
                        />
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={isLimitDuration}
                            onChange={handleChangeLimitDuration}
                          >
                            <FormControlLabel
                              value={t("contact.forms.Undetermined")}
                              control={<Radio />}
                              label={t("contact.forms.Undetermined")}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Fragment>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        );
      })}
      <div className="blc-action center-horizontally align-center space-between">
        <p className="appreciation">{t("contact.appreciation")}</p>
        <button onClick={onSubmit} className="primary-btn">
          {t("contact.send")}
        </button>
      </div>
    </div>
  );
};

export default Form1;
