import React, {useEffect, useRef} from 'react';
import "./ProcessSection.scss";
import TitleSection from "../title-section/TitleSection";
import {useTranslation} from "react-i18next";
import "../../assets/i18n";
import icn1 from "../../assets/icons/icn1-process.svg";
import icn2 from "../../assets/icons/icn2-process.svg";
import icn3 from "../../assets/icons/icn3-process.svg";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";


interface Props {
    firstTitle: string;
    title: string;
    isInternship?: boolean

}

const ProcessSection = (Props: Props) => {
    const {t} = useTranslation();
    const recRef2 = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.fromTo(".titleProcess", {
            y: -100,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 2,
            delay: 2,
            scrollTrigger: {
                trigger: ".titleProcess",
            }
        });
        gsap.fromTo(".blc-text", {
            y: 100,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 2,
            delay: 2,
            scrollTrigger: {
                trigger: ".blc-text",
            }
        });
        gsap.fromTo(".icon", {
            y: -50,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 2,
            delay: 2,
            scrollTrigger: {
                trigger: ".icon",
            }
        });
        gsap.fromTo(".p", {
            y: 50,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 2,
            delay:2,
            scrollTrigger: {
                trigger: ".p",
            }
        });
        gsap.fromTo(".h1", {
            y: -100,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 2,
            delay: 2,
            scrollTrigger: {
                trigger: ".h1",
            }
        });

    }, []);

    return (
        <div className="process-section center-vertically align-center" ref={recRef2}>
            <div className="titleProcess">
            <TitleSection firstTitle={Props.firstTitle} title={Props.title}/>
            </div>
            {
                Props.isInternship ?
                    <div className="blc-text">
                        <p className="p">{t('recruitment.process_internship.text1')}</p>
                        <p className="p">{t('recruitment.process_internship.text2')}</p>
                    </div> :
                    <div className="blc-text">
                        <p className="p"><span>Wimobi</span> {t('recruitment.process_job.text1')}</p>
                        <p className="p"> {t('recruitment.process_job.text2')}</p>
                    </div>
            }
            <div className="processes center-horizontally align-center space-between">
                <div className="item-process">
                    <img src={icn1} alt="icon" className="icon"/>
                    <p className="p">{Props.isInternship ? t('recruitment.process_internship.items.process1') :
                        t('recruitment.process_job.items.process1')}</p>
                    <h1 className="h1">01</h1>
                </div>
                <div className="item-process">
                    <img src={icn2} alt="icon" className="icon"/>
                    <p className="p">{Props.isInternship ? t('recruitment.process_internship.items.process2') :
                        t('recruitment.process_job.items.process2')}</p>
                    <h1 className="h1">02</h1>
                </div>
                <div className="item-process">
                    <img src={icn3} alt="icon" className="icon"/>
                    <p className="p">{Props.isInternship ? t('recruitment.process_internship.items.process3') :
                        t('recruitment.process_job.items.process3')}</p>
                    <h1 className="h1">03</h1>
                </div>
            </div>
        </div>
    );
};

export default ProcessSection;
