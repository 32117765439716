import React from 'react';
import "./CardService.scss";

interface Props {
    icn: string;
    title: string,
    description: string
}

const CardService = (Props: Props) => {
    return (
        <div className="card-service">
            <div className="top-card center-horizontally align-center">
                <div className="icn">
                    <img src={Props.icn} alt="icn"/>
                </div>
                <h1>{Props.title}</h1>
            </div>
            <p>
                {Props.description}
            </p>
        </div>
    );
};

export default CardService;
