import React, { useEffect, useRef } from "react";
import "./RecFirstSection.scss";
import img from "../../assets/images/team.png";
import { useTranslation } from "react-i18next";
import "../../assets/i18n";
import flech from "../../assets/icons/flech.svg";
import shapeTop from "../../assets/icons/shapeTopRec.svg";
import shapeBottom from "../../assets/icons/shapeBottomRec.svg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import icnComment from '../../assets/icons/fill-comment.png'

const RecFirstSection = () => {
  const { t, i18n } = useTranslation();

  const recRef1 = useRef(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.fromTo(
      "#img-rec",
      {
        x: -500,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 1,
        delay: 0.5,
        scrollTrigger: {
          trigger: "#img-rec",
        },
      }
    );
    gsap.fromTo(
      ".top",
      {
        rotate: 360,
        y: -50,
        opacity: 0,
      },
      {
        rotate: 0,
        y: 0,
        opacity: 1,
        duration: 1,
        delay: 0.5,
        scrollTrigger: {
          trigger: ".top",
        },
      }
    );
    gsap.fromTo(
      ".bottom",
      {
        rotate: 360,
        y: 50,
        opacity: 0,
      },
      {
        rotate: 0,
        y: 0,
        opacity: 1,
        duration: 1,
        delay: 0.5,
        scrollTrigger: {
          trigger: ".bottom",
        },
      }
    );
    gsap.fromTo(
      ".abs-title",
      {
        x: 500,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 1,
        delay: 0.5,
        scrollTrigger: {
          trigger: ".abs-title",
        },
      }
    );
    gsap.fromTo(
      ".firstTit",
      {
        y: -100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        delay: 0.5,
        scrollTrigger: {
          trigger: ".firstTit",
        },
      }
    );
    gsap.fromTo(
      ".descript",
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        delay: 0.5,
        scrollTrigger: {
          trigger: ".descript",
        },
      }
    );
    gsap.fromTo(
      ".second-title",
      {
        y: -100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        delay: 0.5,
        scrollTrigger: {
          trigger: ".second-title",
        },
      }
    );
  }, []);

  return (
    <div className="rec-s1" ref={recRef1}>
      <div className="container center-horizontally align-center">
        <div className="blc-img">
          <img src={shapeTop} alt="" className="shape-img top" />
          <img id="img-rec" src={img} alt="img" />
          <img src={shapeBottom} alt="" className="shape-img bottom" />
        </div>
        <div className="right-side center-vertically align-center">
          <div className="title-bord">
            <h1 className="firstTit">
              {t("recruitment.section_1.title1")}
              <span>{t("recruitment.section_1.part2")} </span>
            </h1>
            <div className="abs-title">
              <h1>
                {" "}
                {t("recruitment.section_1.title1")}
                <span> {t("recruitment.section_1.part2")} </span>
              </h1>
            </div>
          </div>
          <p className="descript">
            {t("recruitment.section_1.txt")} <p className='name'>Jack Canfield</p>
            <img src={icnComment} alt="" />
          </p>

          <h1 className="second-title">
            {t("recruitment.section_1.title2")}{" "}
            <img className="flech" src={flech} alt="icon" />
          </h1>
        </div>
      </div>
    </div>
  );
};

export default RecFirstSection;
