import React, {useEffect, useRef} from 'react';
import "./WhoAreWe.scss";
import img from "../../assets/images/img-who.png";
import TitleSection from "../title-section/TitleSection";
import {useTranslation} from "react-i18next";
import "../../assets/i18n";
import shapeTop from "../../assets/icons/shapeTopRec.svg";
import shapeBottom from "../../assets/icons/shapeBottomRec.svg";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const WhoAreWe = () => {
    const {t, i18n} = useTranslation();

    gsap.registerPlugin(ScrollTrigger);
    const refW= useRef(null);


    useEffect(() => {
        gsap.fromTo("#img", {
            x: -500,
            opacity: 0,
        }, {
            x: 0,
            opacity: 1,
            duration: 2,
            delay:1,
            scrollTrigger: {
                trigger: "#img",
            }
        });
        gsap.fromTo(".top", {
            rotate: 360,
            y: -50,
            opacity: 0,
        }, {
            rotate: 0,
            y: 0,
            delay: 1,
            opacity: 1,
            duration: 2,
            scrollTrigger: {
                trigger: ".top",
            }
        });
        gsap.fromTo(".bottom", {
            rotate: 360,
            y: 50,
            opacity: 0,
        }, {
            rotate: 0,
            y: 0,
            opacity: 1,
            duration: 2,
            delay: 1,
            scrollTrigger: {
                trigger: ".bottom",
            }
        });
        gsap.fromTo(".blc-infos", {
            x: 500,
            opacity: 0,
        }, {
            x: 0,
            opacity: 1,
            duration: 2,
            delay:1,
            scrollTrigger: {
                trigger: ".blc-infos",
            }
        });




    }, []);

    return (
        <div className="who-are-section" ref={refW}>
            <div className="container center-horizontally align-center justify-center">
                <div className="blc-imgs">
                    <img src={shapeTop} alt="" className="shape-img top"/>
                    <img src={img} alt="img" id="img"/>
                    <img src={shapeBottom} alt="" className="shape-img bottom"/>
                </div>
                <div className="blc-infos">
                    <TitleSection title={t('about_us.who_are_we.title')} firstTitle={t('about_us.who_are_we.firstTitle')}/>
                    <p className="descript">
                        {t('about_us.who_are_we.txt1')}
                    </p>
                    <p className="descript2">
                        {t('about_us.who_are_we.txt2')}
                    </p>

                </div>
            </div>
        </div>
    );
};

export default WhoAreWe;
