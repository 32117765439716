import React, { useEffect, useRef } from "react";
import "./FirstSection.scss";
import img from "../../assets/images/first-img.png";
import imgAbs from "../../assets/images/temoin-img.png";
import imgAbs2 from "../../assets/images/solution-img.png";
import flechIcn from "../../assets/icons/flech-icn.svg";
import shape from "../../assets/images/shape.png";
import "../../assets/i18n";
import { useTranslation } from "react-i18next";
import { gsap, Power3 } from "gsap";
import { useNavigate } from "react-router";
import { PATHS } from "../../core/enums/Paths";

const FirstSection = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    let tl = gsap.timeline({ delay: 0.2 });
    tl.fromTo(
      ".left-sect",
      { x: -500, opacity: 0, ease: Power3.easeInOut, duration: 2, delay: 0.2 },
      { x: 0, opacity: 1, duration: 2, ease: Power3.easeInOut },
      "Start"
    );
    tl.fromTo(
      ".imgRight",
      { x: 500, opacity: 0, ease: Power3.easeInOut, duration: 2, delay: 0.2 },
      { x: 0, opacity: 1, duration: 2, ease: Power3.easeInOut },
      "Start"
    );
    tl.fromTo(
      ".img-testm",
      { y: -500, opacity: 0, ease: Power3.easeInOut, duration: 2, delay: 0.2 },
      { y: 0, opacity: 1, duration: 2, ease: Power3.easeInOut },
      "Start"
    );
    tl.fromTo(
      ".img-solution",
      { y: 500, opacity: 0, ease: Power3.easeInOut, duration: 2, delay: 0.2 },
      { y: 0, opacity: 1, duration: 2, ease: Power3.easeInOut },
      "Start"
    );
    tl.fromTo(
      ".shape-abs",
      { x: 500, opacity: 0, ease: Power3.easeInOut, duration: 2, delay: 0.2 },
      { x: 0, opacity: 1, duration: 2, ease: Power3.easeInOut },
      "Start"
    );
    tl.fromTo(
      ".flech-icn",
      { scale: 0, opacity: 0, ease: Power3.easeInOut, duration: 2, delay: 0.2 },
      { scale: 1, opacity: 1, duration: 2, ease: Power3.easeInOut },
      0.5
    );
  }, []);

  return (
    <div className="first-section">
      <div className="container center-horizontally space-between align-center">
        <div className="left-sect">
          <div className="sub-title center-horizontally align-center">
            <h3>{t("home.section_1.txt1")}</h3>
          </div>
          <div className="title">
            <h1 className="title-1">{t("home.section_1.title_1")}</h1>
            <h1 className="title-2">{t("home.section_1.title_2")}</h1>
          </div>
          <div className="descript">
            <p>{t("home.section_1.descript")}</p>
          </div>
          <button
            onClick={() => navigate(PATHS.CONTACT)}
            className="primary-btn"
          >
            {t("home.section_1.btn")}
          </button>
        </div>
        <div className="right-sect">
          <img src={imgAbs} alt="" className="img-testm" />
          <img src={imgAbs2} alt="" className="img-solution" />
          <img className="imgRight" src={img} alt="" />
        </div>
        <img src={flechIcn} className="flech-icn" alt="icn" />
      </div>
      <img src={shape} className="shape-abs" alt="" />
    </div>
  );
};

export default FirstSection;
